import React from "react";
import { useState, useEffect } from "react";
import CompareCard from "./CompareCard";
import CompareMeter from "./CompareMeter";
import HealthScore from "./HealthScore";
import iIcon from "../../../../../assets/images/iIcon.svg";
import DashboardFooter from "../DashboardFooter";
import {
  DAY_CARE_KEY,
  PRE_POST_HOSPITALIZATION_KEY,
  RESTORATION_KEY,
  ROOM_RENT_CAPPING_KEY,
  SUM_INSURED_KEY,
} from "../../../../../utils/constants/porting";

function MatchingSection({
  firstName,
  userFeatures,
  suggestedFeatures,
  matchingScore,
  creditScore,
}) {
  function compareValue(keyToCompare) {
    if (userFeatures != null && !suggestedFeatures != null) {
      const userValue = userFeatures[keyToCompare];
      const suggestedValue = suggestedFeatures[keyToCompare];
      if (userValue !== undefined && suggestedValue !== undefined) {
        if (
          typeof userValue === "boolean" &&
          typeof suggestedValue === "boolean"
        ) {
          // For boolean values, return true if suggestedValue is true and userValue is false
          if (keyToCompare === ROOM_RENT_CAPPING_KEY) {
            return userValue === false || suggestedValue === userValue;
          }
          return userValue === true || suggestedValue === userValue;
        } else if (
          typeof userValue === "number" &&
          typeof suggestedValue === "number"
        ) {
          if (keyToCompare === SUM_INSURED_KEY) {
            return userValue >= suggestedValue;
          }
          // For numeric values, return true if suggestedValue is less than userValue
          return suggestedValue < userValue;
        } else {
          return false;
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  const [activeCard, setActiveCard] = useState(0);
  const [startX, setStartX] = useState(0);
  const [endX, setEndX] = useState(0);
  const [direction, setDirection] = useState("");
  const [isSliding, setIsSliding] = useState(false);

  const slideDuration = 500; // Slide transition duration in ms
  const autoSlideInterval = 7000; // Auto-slide interval in ms

  // // Automatic scroll effect for mobile view
  useEffect(() => {
    if (isSliding) {
      const timeout = setTimeout(() => {
        setIsSliding(false);
      }, slideDuration);

      return () => clearTimeout(timeout);
    }
  }, [isSliding, activeCard]);

  useEffect(() => {
    if (!isSliding) {
      const interval = setInterval(() => {
        nextCard();
      }, autoSlideInterval);

      return () => clearInterval(interval);
    }
  }, [isSliding]);

  const nextCard = () => {
    setDirection("next");
    setActiveCard((prevActiveCard) =>
      prevActiveCard === 3 ? 0 : prevActiveCard + 1
    );
  };

  const prevCard = () => {
    setDirection("prev");
    setActiveCard((prevActiveCard) =>
      prevActiveCard === 0 ? 3 : prevActiveCard - 1
    );
  };

  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setEndX(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (startX - endX > 50) {
      nextCard();
    }

    if (endX - startX > 50) {
      prevCard();
    }
  };

  return (
    <>
      <div className="flex flex-col pt-2 px-2 pb-2">
        {/* <header className="flex gap-5 mt-5 max-md:flex-wrap max-md:mr-2.5 max-md:max-w-full">
        <h1 className="flex-auto md:text-4xl text-2xl md:mb-10 font-bold text-indigo-950 text-opacity-70 max-md:max-w-full">
          {firstName
            ? "Hi " + firstName + ","
            : "Where your Health Insurance Stand"}
        </h1>
      </header> */}

        {/* Web View Grid */}
        <div className=" md:grid grid-cols-3 md:gap-10">
          <div className="flex flex-col md:gap-5 gap-10 mt-3 md:mt-0">
            {userFeatures && <CompareMeter score={matchingScore ?? 0} />}

            <CompareCard
              type={SUM_INSURED_KEY}
              isMatching={compareValue(SUM_INSURED_KEY)}
              actualValue={userFeatures ? userFeatures[SUM_INSURED_KEY] : null}
              suggestedValue={suggestedFeatures[SUM_INSURED_KEY]}
            />

            <div className="mt-6 md:0 hidden md:block">
              <DashboardFooter creditScore={creditScore} />
            </div>
          </div>

          <div className="flex flex-col gap-5 md:gap-5 gap-10 mt-4 md:mt-0">
            <CompareCard
              type={ROOM_RENT_CAPPING_KEY}
              isMatching={compareValue(ROOM_RENT_CAPPING_KEY)}
              actualValue={
                userFeatures ? userFeatures[ROOM_RENT_CAPPING_KEY] : null
              }
              suggestedValue={suggestedFeatures[ROOM_RENT_CAPPING_KEY]}
            />
            <CompareCard
              type={RESTORATION_KEY}
              isMatching={compareValue(RESTORATION_KEY)}
              actualValue={userFeatures ? userFeatures[RESTORATION_KEY] : null}
              suggestedValue={suggestedFeatures[RESTORATION_KEY]}
            />
          </div>

          <div className="flex flex-col gap-5 md:gap-5 gap-10 mt-4 md:mt-0">
            <CompareCard
              type={DAY_CARE_KEY}
              isMatching={compareValue(DAY_CARE_KEY)}
              actualValue={userFeatures ? userFeatures[DAY_CARE_KEY] : null}
              suggestedValue={suggestedFeatures[DAY_CARE_KEY]}
            />
            <CompareCard
              type={PRE_POST_HOSPITALIZATION_KEY}
              isMatching={compareValue(PRE_POST_HOSPITALIZATION_KEY)}
              actualValue={
                userFeatures ? userFeatures[PRE_POST_HOSPITALIZATION_KEY] : null
              }
              suggestedValue={suggestedFeatures[PRE_POST_HOSPITALIZATION_KEY]}
            />
          </div>
        </div>

        {/* Mobile View with automatic scrolling */}
        {/* <div className="md:hidden flex flex-col gap-5">
					<div className="flex flex-col gap-5 mt-2">
						{userFeatures && <CompareMeter score={matchingScore ?? 0} />}
						<CompareCard
							type={SUM_INSURED_KEY}
							isMatching={compareValue(SUM_INSURED_KEY)}
							actualValue={userFeatures ? userFeatures[SUM_INSURED_KEY] : null}
							suggestedValue={suggestedFeatures[SUM_INSURED_KEY]}
						/>
					</div>

					<p className="md:hidden text-xl text-left font-bold text-[#020064] mt-2">Discover must-have features for your Health Insurance policy!</p>

					<div
						className="relative min-h-[330px] max-w-screen"
						onTouchStart={handleTouchStart}
						onTouchMove={handleTouchMove}
						onTouchEnd={handleTouchEnd}
					>
						<div className={`carousel-item ${activeCard === 0 ? "visible" : direction === "next" ? "hidden-left" : "hidden-right"}`}>
							<CompareCard
								type={ROOM_RENT_CAPPING_KEY}
								isMatching={compareValue(ROOM_RENT_CAPPING_KEY)}
								actualValue={userFeatures ? userFeatures[ROOM_RENT_CAPPING_KEY] : null}
								suggestedValue={suggestedFeatures[ROOM_RENT_CAPPING_KEY]}
							/>
						</div>
						<div className={`carousel-item ${activeCard === 1 ? "visible" : direction === "next" ? "hidden-left" : "hidden-right"}`}>
							<CompareCard
								type={RESTORATION_KEY}
								isMatching={compareValue(RESTORATION_KEY)}
								actualValue={userFeatures ? userFeatures[RESTORATION_KEY] : null}
								suggestedValue={suggestedFeatures[RESTORATION_KEY]}
							/>
						</div>
						<div className={`carousel-item ${activeCard === 2 ? "visible" : direction === "next" ? "hidden-left" : "hidden-right"}`}>
							<CompareCard
								type={DAY_CARE_KEY}
								isMatching={compareValue(DAY_CARE_KEY)}
								actualValue={userFeatures ? userFeatures[DAY_CARE_KEY] : null}
								suggestedValue={suggestedFeatures[DAY_CARE_KEY]}
							/>
						</div>
						<div className={`carousel-item ${activeCard === 3 ? "visible" : direction === "next" ? "hidden-left" : "hidden-right"}`}>
							<CompareCard
								type={PRE_POST_HOSPITALIZATION_KEY}
								isMatching={compareValue(PRE_POST_HOSPITALIZATION_KEY)}
								actualValue={
									userFeatures
										? userFeatures[PRE_POST_HOSPITALIZATION_KEY]
										: null
								}
								suggestedValue={suggestedFeatures[PRE_POST_HOSPITALIZATION_KEY]}
							/>
						</div>
					</div>
				</div>
				{/* Dot Indicators Below the cards */}
        {/* <div className="md:hidden flex justify-center mt-4">
					{[...Array(4)].map((_, index) => (
						<div
							key={index}
							onClick={() => setActiveCard(index)}
							className={`h-2 w-2 rounded-full mx-1 cursor-pointer ${index === activeCard ? "bg-blue-500" : "bg-gray-300"
								}`}
						></div>
					))}
				</div>  */}

        <div className="mt-6 md:0 md:hidden">
          <DashboardFooter creditScore={creditScore} />
        </div>
      </div>
    </>
  );
}

export default MatchingSection;
