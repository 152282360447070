import React from 'react';

const UserDetailsHeader = ({ panNo, age, pincode }) => {
  return (
    <div className="pt-4 font-primary-font">
      <div className="flex flex-col-reverse sm:flex-row max-w-6xl items-center">
        <div className="flex border-2 border-primary-color rounded-3xl p-1 text-sm md:text-md mb-4 sm:mb-0">
          {panNo && (
            <span className="m-2 text-primary-color font-semibold">
              PAN No: <span className="font-normal">{panNo}</span>
            </span>
          )}
          <span className="m-2 text-primary-color font-semibold">Age: <span className="font-normal">{age}</span></span>
          <span className="m-2 text-primary-color font-semibold">Pincode: <span className="font-normal">{pincode}</span></span>
        </div>
      </div>
    </div>
  );
};

export default UserDetailsHeader;
