import React from 'react';
import IpLogo from '../../assets/images/ip_icon.png';
import Logo from '../../assets/images/ip_logo.svg';
import Dropdown from "./Dropdown";
import { useSelector } from "react-redux";
import { isObjectEmpty } from "../../utils/common";
import { capitalizeFirstLetter, getFirstLetter, isMobile } from "../../utils/validations";

const Header = () => {

    const sessionData = useSelector((state) => state?.session?.sessionData) || {};

    return (
        <div className={'flex justify-between pt-4 lg:pt-6 pb-4 px-8 lg:p-5 lg:px-16'} >
            <>
                <div className='flex items-center md:basis-1/3 basic-2/3'>
                    <div className='mr-3 mb-1 hidden md:block'>
                        <a href='/' className=' flex' style={{ width: "45px", height: "45px" }}>
                            <img src={IpLogo} alt="IpLogo" />
                        </a>
                    </div>
                    <div className='hidden md:block'>
                        <a href='/'><img src={Logo} alt="Logo" /></a>
                    </div>
                    <div className='mr-2 mb-1 md:hidden'>
                        <a href='/' className=' flex' style={{ width: "25px", height: "25px" }}>
                            <img src={IpLogo} alt="IpLogo" />
                        </a>
                    </div>
                    <div className='md:hidden'>
                        <a href='/' className='flex' style={{ width: "140px" }}>
                            <img src={Logo} alt="Logo" />
                        </a>
                    </div>
                </div>
                <div className='md:basic-2/3 flex items-center justify-start'>
                    <div class="flex items-center space-x-6 text-primary-color font-semibold font-primary-font text-sm">
                        <div className='hidden md:block space-x-6'>
                            <a href="https://www.insurancepadosi.in/blog/">Blogs</a>
                            <a href="https://www.insurancepadosi.in/about-us.html">Company</a>
                            <a href="https://www.insurancepadosi.in/faq.html">FAQs</a>
                        </div>
                        {
                            !isObjectEmpty(sessionData) && (
                                <Dropdown label={sessionData.fname || sessionData.lname ? `${!isMobile() ?
                                    `${capitalizeFirstLetter(sessionData?.fname)} ${capitalizeFirstLetter(sessionData?.lname)}`
                                    : `${getFirstLetter(capitalizeFirstLetter(sessionData?.fname))}${getFirstLetter(capitalizeFirstLetter(sessionData?.lname))}`}` : ''} />
                            )
                        }{
                            isObjectEmpty(sessionData) && (
                                <a href="https://app.insurancepadosi.in/" class="bg-secondary-color text-xs md:text-sm font-semibold text-white py-1 md:py-2 px-1 md:px-4 rounded" >Sign In</a>
                            )
                        }
                    </div>
                </div>
            </>
        </div >
    );
};

export default Header;