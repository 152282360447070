import React from "react";
import Check from "../../../../assets/images/port-icons/check-circle.svg";
import Icon from "../../../../assets/images/childihr.png";
import Tick from "../../../../assets/images/tick.png";
import { formatCash, getCorpusRequiredAge } from "../../../../utils/common";

const ChildPlanCard = ({
  childOneCorpus,
  childTwoCorpus,
  childOneAge,
  childTwoAge,
  annualInvestment,
  canShow,
}) => {
  const insuranceFeatures = (text) => (
    <div className="flex w-full items-center">
      <img loading="lazy" src={Tick} alt="Feature icon" className="" />
      <p
        className={`ml-2 text-xs md:text-sm text-secondary-color ${!canShow ? "blur-sm" : ""}`}
      >
        {text}
      </p>
    </div>
  );

  function calculateChildCorpusYear(childAge) {
    var now = new Date();
    var currentYear = now.getFullYear();
    var corpusRequiredAge = getCorpusRequiredAge(childAge);
    var childAgeInt = parseInt(childAge);

    var childOneCorpusYear = currentYear + (corpusRequiredAge - childAgeInt);
    return childOneCorpusYear;
  }

  return (
    <div className="pl-1.5 rounded-3xl shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-11/12 md:w-10/12 mt-6 bg-[#d38600] font-primary-font">
      <div className="bg-white rounded-2xl">
        <div className="flex flex-col w-full max-md:max-w-full">
          <div className="bg-[#d38600] py-8 px-4 md:px-12 text-white rounded-t-2xl">
            <header className="self-start ml-2 text-xl max-md:max-w-full flex items-center">
              <img
                src={Icon}
                alt="Child Icon"
                className="w-6 h-6 md:w-8 md:h-8 mr-2 mb-2 md:mb-6"
              />
              <div>
                <div className="text-md md:text-3xl font-semibold">
                  Child Planning
                </div>
                <div className="text-sm md:text-lg">
                  Securing the {childTwoAge != null ? "children's" : "child's"}{" "}
                  future with sufficient corpus to support their education
                </div>
              </div>
            </header>
          </div>

          <div className="px-2 md:px-4">
            <div className="flex flex-col gap-2 md:gap-5 self-center px-7 py-4 mt-2 w-full text-left md:text-center max-w-[930px] max-md:flex-wrap max-md:px-5 max-md:max-w-full">
              <div className="w-full flex-auto">
                <div className="grid grid-cols-2 gap-4">
                  <div className="flex flex-col col-span-1">
                    <p className="text-primary-color font-bold text-sm md:text-md">
                      Required Corpus
                    </p>
                    <div className="col-span-1 text-tertiary-color text-sm md:text-xl font-bold mt-1">
                      {formatCash(childOneCorpus)}
                    </div>
                    {childTwoCorpus > 0 && (
                      <div className="col-span-1 text-tertiary-color text-sm md:text-xl font-bold mt-1">
                        {formatCash(childTwoCorpus)}
                      </div>
                    )}
                  </div>

                  <div className="flex flex-col col-span-1">
                    <p className="font-bold text-sm md:text-md text-primary-color">
                      Year
                    </p>
                    <div className="col-span-1 text-tertiary-color text-sm md:text-xl font-bold mt-1">
                      {calculateChildCorpusYear(childOneAge)}
                    </div>
                    {childTwoCorpus > 0 && (
                      <div className="col-span-1 text-tertiary-color text-sm md:text-xl font-bold mt-1">
                        {calculateChildCorpusYear(childTwoAge)}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <hr className="shrink-0 border-solid mx-auto bg-[#848f94] border-[1px] h-[2px] w-[90%] max-md:max-w-full" />

            {annualInvestment != null && annualInvestment > 0 && (
              <div className="flex flex-col pl-5 md:mt-2 w-full max-md:max-w-full">
                <h2 className="self-start mt-4 md:mt-5 md:ml-2 text-md md:text-md font-semibold text-secondary-color max-md:max-w-full">
                  Recommendation:
                </h2>
                <div className="self-center w-full max-md:max-w-full">
                  <div className="flex flex-col items-center mx-2 my-2 max-md:ml-0 max-md:w-full">
                    <div className="w-full">
                      <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                        <div className="flex flex-col col-span-1">
                          <p className="font-bold text-sm md:text-md text-tertiary-color">
                            Annual Investment
                          </p>
                          <div className="col-span-1 text-[#0f4c6e] text-sm md:text-xl font-semibold mt-1">
                            {formatCash(annualInvestment)}
                          </div>
                        </div>

                        <div className="flex flex-col col-span-1">
                          <p className="font-bold text-sm md:text-md text-tertiary-color">
                            Plan
                          </p>
                          <div className="col-span-1 text-[#0f4c6e] text-sm md:text-xl font-semibold mt-1">
                            Traditional/ULIP
                          </div>
                        </div>

                        <div className="flex flex-col col-span-1">
                          <p className="font-bold text-sm md:text-md text-tertiary-color">
                            Recalibration
                          </p>
                          <div className="col-span-1 text-[#0f4c6e] text-sm md:text-xl font-semibold mt-1">
                            Every 4 years
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="flex flex-col pl-2 md:pl-5 w-full max-md:max-w-full mt-3 mb-4">
              <h2 className="self-start ml-2 text-sm md:text-md font-semibold leading-10 text-tertiary-color max-md:max-w-full">
                Why do we recommend it:
              </h2>
              <div className="self-center w-full max-md:max-w-full">
                <div className="flex flex-col items-center w-10/12 mx-2 my-2 max-md:ml-0 max-md:w-full">
                  <div className="w-full">
                    <div className="grid grid-cols-1 md:grid-cols-1 gap-2">
                      {insuranceFeatures(
                        "Let your child's dreams take flight by accumulating funds for their college education, marriage, or business ventures."
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChildPlanCard;
