import { useEffect, useState } from "react";
import toast, { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import successAnimation from "../../../assets/images/celebration.json";
import pendingAnimation from "../../../assets/images/pending.json";
import { useDashboardPostMutation } from "../../../store/services/dashboard";
import { useGetSessionMutation } from "../../../store/services/session";
import { useApi } from "../../../store/services/useApi";
import { setSessionData as setSessionStoreData } from "../../../store/slices/sessionSlice";
import { formatCash } from "../../../utils/common";
import { incomeValues } from "../../../utils/constants/common";
import ORDER_STATUS from "../../../utils/constants/order";
import { RECOMMENDATION_DETAILS } from "../../../utils/constants/productDetails";
import STRINGS from "../../../utils/constants/strings";
import ProfileLoader from "../../Common/Loaders/ProfileLoader";
import Spinner from "../../Common/Loaders/Spinner";
import LottiePopup from "../../Common/LottiePopup";
import AlimentCard from "./Dashboard/AlimentCard";
import ChildPlanCard from "./Dashboard/ChildPlanCard";
import DashboardFooter from "./Dashboard/DashboardFooter";
import HealthInsurancePopup from "./Dashboard/HealthInsurancePopup";
import MatchingSection from "./Dashboard/HealthInsuranceSection/Matching";
import RetirementCard from "./Dashboard/RetirementCard";
import TermInsuranceCard from "./Dashboard/TermInsuranceCard";
import UserDetailsHeader from "./Dashboard/UserDetailsHeader";
import RecommendationCard from "./Recommendation/RecommendationCard";
import productsConfig from "../Common/UserDetailsForm/productsConfig";
import WhatsAppButton from "../../Common/WhatsAppButton";
import FeedbackPopup from "../../Common/FeedbackPopup";


const IhrDashboard = () => {
	const [emailContainer, showEmailContainer] = useState(false);
	const [whatsappContainer, showWhatsappContainer] = useState(false);
	const [loading, setLoading] = useState(true); // Added loading state
	const sessionData =
		useSelector((state) => state?.session?.sessionData) || null;
	const [getSession, { data: getSessionData }] = useGetSessionMutation();
	// Use the useDashboardPostMutation hook
	const dispatch = useDispatch();
	useEffect(() => {
		if (getSessionData) {
			dispatch(setSessionStoreData(getSessionData));
		}
	}, [getSessionData]);

	const [dashboardPost, { data: dashboardData }] = useDashboardPostMutation();

	const {
		data: orderResponse,
		error: orderError,
		isLoading: orderLoading,
		makeCall: createOrderCall,
	} = useApi("POST", "/create-order");
	const [orderSuccess, setOrderSuccess] = useState(false);
	const [orderFailed, setOrderFailed] = useState(false);
	const [orderPending, setOrderPending] = useState(false);
	const [reportUrl, setReportUrl] = useState(null);

	const {
		data: orderStatusData,
		error: orderStatusError,
		isLoading: orderStatusLoading,
		makeCall: checkOrderStatus,
	} = useApi("POST", "/refresh-order-status");
	const [showPopup, setShowPopup] = useState(false);

	const [insuredPhoneNo, setInsuredPhoneNo] = useState(null);
	const [insuredPanNo, setInsuredPanNo] = useState(null);
	const [insurer, setInsurer] = useState(null);
	const [firstName, setFirstName] = useState(null);
	const [policyName, setPolicyName] = useState(null);
	const [cover, setCover] = useState(null);
	const [age, setAge] = useState(null);
	const [pincode, setPincode] = useState(null);
	const [profileLoader, setProfileLoader] = useState(false);
	const [showAirAmbulanceRecom, setAirAmbulanceRecom] = useState(false);
	const [showSportsRecom, setSportsRecom] = useState(false);
	const [showPsycRecom, setPsycRecom] = useState(false);
	const [showSuperTopupRecom, setSuperTopupRecom] = useState(false);
	const [isFeedbackPopupOpen, setIsFeedbackPopupOpen] = useState(false);
	const [feedbackExists, setFeedbackExists] = useState(false);

	const [activeTab, setActiveTab] = useState('matching'); // Default tab

	// Tab click handler
	const handleTabClick = (tab) => {
	setActiveTab(tab);
	};

	// Check if the child card exists, to decide whether to display that tab
	const childPlanExists = dashboardData?.ihrDetails?.childone_cover;

	// Get the current location object
	const location = useLocation();

	// Access the URL parameters

	const [isPopupOpen, setIsPopupOpen] = useState(false);

	const handleOpenPopup = () => {
		setIsPopupOpen(true);
	};

	useEffect(() => {
        if (!feedbackExists) {
            const timer = setTimeout(() => {
                setIsFeedbackPopupOpen(true);
            }, 60000); // 60 seconds

            // Cleanup the timer if the component unmounts
            return () => clearTimeout(timer);
        }
    }, [feedbackExists]);
	
	const handlePopupClose = () => {
        setIsFeedbackPopupOpen(false);
    };

	useEffect(() => {
		if (orderResponse || orderStatusData) {
			let orderData = orderResponse || orderStatusData;
			//set success and failue here.
			if (orderData.order.status === ORDER_STATUS.success) {
				setOrderSuccess(true);
				setOrderPending(false);
				handleOrderSuccess();
				setReportUrl(orderData.report_url);
			}
			if (orderData.order.status === ORDER_STATUS.failed) {
				setOrderFailed(true);
				setOrderPending(false);
			}
			setOrderPending(false);
		}
	}, [orderResponse, orderStatusData]);

	// Function to handle order success
	const handleOrderSuccess = () => {
		// Process the order and then show the Lottie pop-up
		// For demonstration purposes, we'll just show the pop-up immediately
		setShowPopup(true);

		// You can also set a timeout to close the pop-up after a certain duration
		setTimeout(() => {
			setShowPopup(false);
		}, 2000); // Close the pop-up after 2 seconds
	};

	useEffect(() => {
		if (sessionData) {
			setReportUrl(sessionData?.report_url);
			setFeedbackExists(sessionData?.feedback || false);
		}
	}, [sessionData]);

	useEffect(() => {
		if (sessionData) {
			setFirstName(sessionData?.fname);
			setAge(sessionData?.age);
			setInsuredPanNo(sessionData?.userPanNo);
			setPincode(sessionData?.pincode);
		}
	}, [sessionData]);

	useEffect(() => {
		if (dashboardData) {
			const { mobile } = dashboardData;
			setInsuredPhoneNo(mobile || "");
			if (dashboardData.userHealthInsuranceFeatures) {
				setInsurer(
					dashboardData.userHealthInsuranceFeatures.insurer_name
				);
				setPolicyName(
					dashboardData.userHealthInsuranceFeatures.product_name
				);
				setCover(
					formatCash(
						dashboardData.userHealthInsuranceFeatures.sum_insured
					)
				);
			}
		}
	}, [dashboardData]);

	useEffect(() => {
		if (sessionData) {
			const urlParams = new URLSearchParams(location.search);
			let orderId = urlParams.get("orderid");
			if (orderId) {
				if (Object.entries(sessionData).length && !loading) {
					checkOrderStatus({ order_id: orderId });
					setOrderPending(true);
				}
			}
		}
	}, [sessionData, loading]);

	useEffect(() => {
		const fetchData = async () => {
			await dashboardPost({
				// "userid": localStorage.getItem('userid'),
			});
			setLoading(false); // Set loading to false when data is fetched
		};

		// Introduce a delay of 5 seconds before fetching data
		const delay = 5000; // 5 seconds
		const timeoutId = setTimeout(fetchData, delay);

		return () => clearTimeout(timeoutId); // Cleanup the timeout on unmount
	}, []);

	useEffect(() => {
		if (location.state) {
			let refreshSession = location.state["refreshSession"];
			if (refreshSession === true) {
				getSession();
				location.state["refreshSession"] = false;
				setProfileLoader(true);

			}
		}
	}, [])

	const removeLoader = () => {
		setProfileLoader(false)
	}

	const handleEmail = (event) => {
		showWhatsappContainer(false);
		showEmailContainer(true);
	};

	const handleWhatsapp = (event) => {
		showEmailContainer(false);
		showWhatsappContainer(true);
	};


	let isIhrAccessible = true;

	const canShowChildCorpus = () => {
		if (dashboardData.ihrDetails.childone_cover && dashboardData.ihrDetails.childone_cover > 0) {
			return dashboardData.ihrDetails
				.childOneAge < 15 && dashboardData.ihrDetails
					.childTwoAge < 15
		}
	}

	useEffect(() => {
		if (sessionData && dashboardData) {
			const { age: userAge, isburea: isBureauMember, userProfile, userProducts } = sessionData;
			const creditScore = parseInt(dashboardData.creditscore, 10) || 0;

			const incomeDeclaration = userProfile.income || 0;

			// Helper function to check if the user already has a specific product
			const hasProduct = (productId) => userProducts.some(product => product.product_id === productId);

			// Set Air Ambulance Recommendation
			if (!hasProduct(productsConfig['air-ambulance'].id)) {
				setAirAmbulanceRecom(incomeDeclaration > 1500000);
			}

			// Set Sports Insurance Recommendation
			if (!hasProduct(productsConfig['sports-insurance'].id)) {
				const shouldRecommendSportsInsurance = userAge <= 25 || (userAge > 25 && creditScore >= 750);
				setSportsRecom(shouldRecommendSportsInsurance);
			}

			// Set Psychological Counselling Recommendation
			if (!hasProduct(productsConfig['psychological-counseling'].id)) {
				setPsycRecom(isBureauMember);
			}

			// Super Top-Up Recommendation
			if (!hasProduct(productsConfig['super-topup'].id)) {
				const userSumInsured = dashboardData.userHealthInsuranceFeatures?.sum_insured;
				const suggestedSumInsured = dashboardData.suggestedHealthInsuranceFeatures.sum_insured;
	
				if (userSumInsured !== null && userSumInsured < suggestedSumInsured) {
					setSuperTopupRecom(true);
				} else {
					setSuperTopupRecom(false);
				}
			}
		}
	}, [sessionData, dashboardData]);



	const handleRefreshDashboard = () => {
		setLoading(true);
		getSession();
		dashboardPost().then(() => {
			setLoading(false);
		});
	};

	const showRecommendations = false;

	return (
		<>
		  <Toaster />
			{sessionData && profileLoader && <ProfileLoader userData={sessionData} removeLoader={removeLoader} />} {/* Show spinner while loading */}
			{!profileLoader && loading && <Spinner />} {/* Show spinner while loading */}
			{showPopup && (
				<LottiePopup
					animationData={successAnimation}
					closeDelay={2000}
					width="500px"
					height="500px"
				/>
			)}
			{orderPending && (
				<LottiePopup
					animationData={pendingAnimation}
					closeDelay={5000}
				/>
			)}
			{!loading && !profileLoader && dashboardData /* Show content when not loading */ && (
				<div className="w-full bg-[#f7f9f9] shadow-[0_-2px_4px_-1px_rgba(0,0,0,0.1)] md:px-12">
					{/* Port no port page header for users that have health insurance */}
					{/* {sessionData != null && (
						<DashboardHeader
							insuredPhoneNo={insuredPhoneNo}
							insuredPanNo={insuredPanNo}
							age={age}
							pincode={pincode}
							policyName={policyName}
							insurer={insurer}
							cover={cover}
							isburea={sessionData.isburea}
							showChange={insurer == null || sessionData.healthPlanChangeCount < 2}
							healthValues={dashboardData.healthValues}
							isEmployer={dashboardData.isEmployer}
						/>
					)} */}

					{sessionData != null && (
					<div className="text-center md:text-left px-4">
						<UserDetailsHeader
						panNo={insuredPanNo}
						age={age}
						pincode={pincode}
						/>
					</div>
					)}


<div className="mb-6">
      {/* Tab Header */}
      <div className="flex space-x-4 overflow-x-auto md:overflow-hidden py-4 px-4 font-primary-font">
        {/* Tabs */}
        <button 
          className={`px-4 py-2 rounded-xl  shadow-lg ${activeTab === 'matching' ? 'bg-primary-color text-white' : 'bg-white text-primary-color'} border-b-2 whitespace-nowrap`}
          onClick={() => handleTabClick('matching')}
        >
          Health Insurance Report
        </button>
        <button 
          className={`px-4 py-2 rounded-xl shadow-lg ${activeTab === 'ailment' ? 'bg-primary-color text-white' : 'bg-white text-primary-color'} border-b-2 whitespace-nowrap`}
          onClick={() => handleTabClick('ailment')}
        >
          Illness Related
        </button>
        <button 
          className={`px-4 py-2 rounded-xl shadow-lg ${activeTab === 'term' ? 'bg-primary-color text-white' : 'bg-white text-primary-color'} border-b-2 whitespace-nowrap`}
          onClick={() => handleTabClick('term')}
        >
          Term Insurance
        </button>
        {childPlanExists !== 0 && childPlanExists && (
          <button 
            className={`px-4 py-2 rounded-xl shadow-lg ${activeTab === 'child' ? 'bg-primary-color text-white' : 'bg-white text-primary-color'} border-b-2 whitespace-nowrap`}
            onClick={() => handleTabClick('child')}
          >
            Child Planning
          </button>
        )}
        <button 
          className={`px-4 py-2 rounded-xl shadow-lg ${activeTab === 'retirement' ? 'bg-primary-color text-white' : 'bg-white text-primary-color'} border-b-2 whitespace-nowrap`}
          onClick={() => handleTabClick('retirement')}
        >
          Retirement Planning
        </button>
      </div>

      {/* Content Based on Active Tab */}
      <div className="px-4 pb-4">
        {activeTab === 'matching' && (
          <MatchingSection
            firstName={firstName}
            userFeatures={dashboardData.userHealthInsuranceFeatures}
            suggestedFeatures={dashboardData.suggestedHealthInsuranceFeatures}
            matchingScore={dashboardData.matchingHealthInsScore}
			creditScore={dashboardData?.creditscore}
          />
        )}

        {activeTab === 'ailment' && (
          <div className="relative flex flex-col items-center">
            <AlimentCard
              cancerCareValue={dashboardData.ihrDetails.cancer_care}
              cardiacCareValue={dashboardData.ihrDetails.cardiac_care}
              retirementYears={dashboardData.retirementAge}
              canShow={isIhrAccessible}
            />
        </div>
        )}

        {activeTab === 'term' && (
			<div className="relative flex flex-col items-center">
          <TermInsuranceCard
            value={dashboardData.ihrDetails.estate_planning}
            retirementYears={dashboardData.retirementAge}
            canShow={isIhrAccessible}
          />
		  </div>
        )}

        {activeTab === 'child' && childPlanExists && (
			<div className="relative flex flex-col items-center">
          <ChildPlanCard
            childOneCorpus={dashboardData.ihrDetails.childone_cover}
            childTwoCorpus={dashboardData.ihrDetails.childtwo_cover}
            childOneAge={dashboardData.ihrDetails.childOneAge}
            childTwoAge={dashboardData.ihrDetails.childTwoAge}
            annualInvestment={dashboardData.ihrDetails.childrenAnnualInvestment}
            canShow={isIhrAccessible}
          />
		  </div>
        )}

        {activeTab === 'retirement' && (
			<div className="relative flex flex-col items-center">
          <RetirementCard
            retirementAmount={dashboardData.ihrDetails.corpus}
            deferredAnnuity={dashboardData.ihrDetails.deferred_annuity}
            retirementYears={dashboardData.retirementAge}
            annualInvestment={dashboardData.ihrDetails.pensionInvestmentAmount}
            canShow={isIhrAccessible}
          />
		  </div>
        )}
      </div>
    </div>

			
						
						{/* <div className="flex justify-center lg:justify-end w-full lg:w-auto">
  							<WhatsAppButton />
						</div> */}

						<div>
							{isFeedbackPopupOpen && (
								<FeedbackPopup onClose={handlePopupClose} />
							)}
						</div>



						{/* {dashboardData.userHealthInsuranceFeatures ? (
							sessionData?.healthPlanChangeCount < 2 ? (
								<div className="relative bg-[#F4CD2A] p-4 text-center mb-14">
									<div className="flex flex-col md:flex-row justify-center items-center">
										<p className="text-black font-bold mb-0 md:mr-8">
											Want to update your health insurance plan?
										</p>
										<button
											className="bg-[#207058] text-white font-bold px-4 py-2 mt-2 rounded-xl md:mt-0"
											onClick={handleOpenPopup}
										>
											Click Here
										</button>
									</div>
									{isPopupOpen && (
										<HealthInsurancePopup onClose={() => setIsPopupOpen(false)} refreshDashboard={handleRefreshDashboard} />
									)}
								</div>
							) : null
						) : (
							<div className="relative bg-[#F4CD2A] p-4 text-center mb-14">
								<div className="flex flex-col md:flex-row justify-center items-center">
									<p className="text-black font-bold mb-0 md:mr-6">
										Already have health insurance? Simply add your current details to see if your coverage is sufficient or if you might need more protection.
									</p>
									<button
										className="bg-[#207058] text-white font-bold px-4 py-2 rounded-xl mt-2 md:mt-0"
										onClick={handleOpenPopup}
									>
										Click Here
									</button>
								</div>
								{isPopupOpen && (
									<HealthInsurancePopup onClose={() => setIsPopupOpen(false)} refreshDashboard={handleRefreshDashboard} />
								)}
							</div>
						)} */}


						{/* Hidden */}
						{dashboardData.ihrDetails != null && (
							<div>
								{/* Section 1 - Centered heading - Congrats */}
								<div className=" bg-white rounded-md">
									{/* <h1 className="flex-auto md:ml-10 text-lg md:text-4xl font-bold text-black text-opacity-70 max-md:max-w-full max-md:px-5">
										{STRINGS.Dashboard.conversTitle}
									</h1> */}
									{/* Recommender products */}
									{showRecommendations && (
									<div className="mt-10">
										<div className="mb-10">
											{showSuperTopupRecom &&
												<RecommendationCard
													title={RECOMMENDATION_DETAILS["super-top-up"].title}
													recommendationText={RECOMMENDATION_DETAILS["super-top-up"].description}
													imageUrl={RECOMMENDATION_DETAILS["super-top-up"].icon}
													redirectUrl={RECOMMENDATION_DETAILS["super-top-up"].url}
												/>
											}
										</div>
										<div className="mb-10">
											{showSportsRecom &&
												<RecommendationCard
													title={RECOMMENDATION_DETAILS["sports-cover"].title}
													recommendationText={RECOMMENDATION_DETAILS["sports-cover"].description}
													imageUrl={RECOMMENDATION_DETAILS["sports-cover"].icon}
													redirectUrl={RECOMMENDATION_DETAILS["sports-cover"].url}
												/>
											}
										</div>
										<div className="mb-10">
											{showAirAmbulanceRecom &&
												<RecommendationCard
													title={RECOMMENDATION_DETAILS["air-ambulance"].title}
													recommendationText={RECOMMENDATION_DETAILS["air-ambulance"].description}
													imageUrl={RECOMMENDATION_DETAILS["air-ambulance"].icon}
													redirectUrl={RECOMMENDATION_DETAILS["air-ambulance"].url}
												/>
											}
										</div>
										<div className="mb-10">
											{showPsycRecom &&
												<RecommendationCard
													title={RECOMMENDATION_DETAILS["psychological-conver"].title}
													recommendationText={RECOMMENDATION_DETAILS["psychological-conver"].description}
													imageUrl={RECOMMENDATION_DETAILS["psychological-conver"].icon}
													redirectUrl={RECOMMENDATION_DETAILS["psychological-conver"].url}
												/>
											}
										</div>
									</div>
									)}
									
								</div>
							</div>
						)}

					
				</div >
			)}
		</>
	);

};

export default IhrDashboard;
