import React, { useState, useEffect } from 'react';
import { useApi } from '../../store/services/useApi';
import Spinner from './Loaders/Spinner';
import FeedbackIcon from "../../assets/images/feedbackicon.svg";
import toast, { Toaster } from 'react-hot-toast';

const FeedbackPopup = ({ onClose }) => {
  const [rating, setRating] = useState(5); // Default rating set to 5
  const [comment, setComment] = useState('');
  const [commentError, setCommentError] = useState('');
  const [charCount, setCharCount] = useState(0);

  const { makeCall: submitFeedback, isLoading: isPosting, data:feedbackResponse, error: postError } = useApi('POST', '/user/feedback');
  
  const handleRatingSelect = (selectedRating) => {
    setRating(selectedRating);
  };

  const handleCommentChange = (e) => {
    const newComment = e.target.value;
    const newCharCount = newComment.length;
    if (newComment.length > 200) {
      setCommentError('Maximum of 200 characters allowed');
    } else {
      setCommentError('');
    }
    setComment(newComment);
    setCharCount(newCharCount);
  };

  useEffect(() => {
    if (feedbackResponse || postError) {
      if (feedbackResponse?.feedbackSaved) {
        toast.success("Thanks for the feedback", {
          duration: 5000,
          position: "top-center",
        });
      } else {
        toast.error("Something went wrong, please try again later", {
          duration: 5000,
          position: "top-center",
        });
      }
      onClose(); // Close the popup after showing the toast
    }
  }, [feedbackResponse, postError, onClose]);

  const handleSubmit = async () => {
    const payload = {
      rating: rating,
      comment: comment || null, // Optional comment
      product_id: 1 // Hardcoded product_id
    };
    try {
      await submitFeedback(payload);
    } catch {
      console.log("error");
    }
  };

  const getConditionalText = () => {
    if (rating >= 1 && rating <= 2) {
      return "We’re sorry to hear we didn’t meet your expectations. Could you tell us how we can improve your experience?";
    } else if (rating >= 3 && rating <= 4) {
      return "We’re glad to know we delivered a reasonable experience. What can we do better to fully meet your expectations?";
    } else if (rating === 5) {
      return "We’re thrilled to hear that you found our report helpful! What did you like the most, and how can we make it even better?";
    }
    return "";
  };

  return (
    <>
    <Toaster />
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 font-primary-font">
      <div className="bg-white p-6 rounded-md shadow-md w-full max-w-lg mx-4 sm:mx-auto my-8 relative">
        
        <div className="flex items-center justify-between mb-4 mx-3 mt-2">
            <div className="flex items-center">
                <img src={FeedbackIcon} alt="Feedback Icon" className="w-10 h-10 mr-3" />
                <h2 className="text-lg font-bold text-tertiary-color">Feedback</h2>
            </div>

            <button onClick={onClose} className="text-gray-500 hover:text-gray-700 text-2xl mb-2">
                &times;
            </button>
        </div>

        <hr className="border-[#7B7D7E] mx-4 mb-8"/>

        <h2 className="text-md font-bold text-center text-tertiary-color mb-2">Rate Your Insurance Health Report Experience</h2>
        <p className="text-center text-sm text-tertiary-color mb-1">We’d love to know if we met your expectations in helping you understand your health insurance needs</p>

        {/* Rating stars */}
        <div className="flex justify-center mb-4">
          {[1, 2, 3, 4, 5].map((star) => (
            <span
              key={star}
              className={`cursor-pointer p-1 text-5xl ${star <= rating ? 'text-[#d38600]' : 'text-[#DBDBDB]'}`}
              onClick={() => handleRatingSelect(star)}
            >
              ★
            </span>
          ))}
        </div>

          {/* Display conditional text based on rating */}
          <p className="text-center text-xs text-tertiary-color mb-2">{getConditionalText()}</p>

          <div className="mb-4">
            <textarea
              className="w-full p-2 border border-gray-300 rounded"
              placeholder="Add a Comment..."
              value={comment}
              onChange={handleCommentChange}
              rows="3"
            />
            <p className={`text-right text-xs ${charCount > 200 ? 'text-red-500' : 'text-tertiary-color'} mt-1`}>
              {charCount}/200
            </p>
            {commentError && (
              <p className="text-red-500 text-xs text-center mt-1">{commentError}</p>
            )}
          </div>

        <button
          type="button"
          className={`flex mx-auto px-6 justify-center items-center py-2 rounded text-white font-bold ${commentError ? 'bg-gray-400 cursor-not-allowed' : 'primary-btn hover:bg-[#1a5b4d]'}`}
          onClick={handleSubmit}
          disabled={commentError !== ''}
        >
          {isPosting ? (
            <>
              Submitting...
              <Spinner />
            </>
          ) : (
            'Submit Now'
          )}
        </button>
      </div>
    </div>
    </>
  );
};

export default FeedbackPopup;
