import roomRentCappingIcon from "../../assets/images/port-icons/Room-rent-capping.svg";
import dayCareProcedureIcon from "../../assets/images/port-icons/Day-care-procedure.svg";
import restorationIcon from "../../assets/images/port-icons/Restoration.svg";
import prePostHospitalizationIcon from "../../assets/images/port-icons/Pre-post-hospitalisation.svg";
import sumInsuredIcon from "../../assets/images/port-icons/sum-insured.svg";
import SI from "../../assets/images/suminsuredihr.png";
import DC from "../../assets/images/daycareihr.png";
import PH from "../../assets/images/prepostihr.png";
import RE from "../../assets/images/restorationihr.png";

export const ROOM_RENT_CAPPING_KEY = "room_rent_capping";
export const PRE_POST_HOSPITALIZATION_KEY = "pre_post_hospitalization";
export const RESTORATION_KEY = "restoration";
export const DAY_CARE_KEY = "day_care";
export const SUM_INSURED_KEY = "sum_insured";
export const DAY_CARE_PROCEDURE_KEY = "day_care";
export const CO_PAY_KEY = "co_pay";
export const FAMILY_FLOATER_KEY = "family_floater";
export const CRITICAL_ILLNESS_KEY = "critical_illness";

export const HEALTH_INSURANCE_COMPARE_KEYS = {
	[SUM_INSURED_KEY]: {
		title: "Required Sum Insured",
		description: "",
		matchingInfo:
			"Congratulations, you are among 2% of users who have an ideal sum insured. Your ideal sum insured is ",
		notMatchingInfo: "The sum insured refers to the maximum amount an insurer will pay for covered medical expenses during a policy year.",
		icon: SI,
		color: "#0f4c6e",
		
	},
	[ROOM_RENT_CAPPING_KEY]: {
		title: "Room Rent Capping",
		description: "Limit on room rent coverage",
		matchingInfo:
			"Congratulations , you are among 9% of users who have  ideal room rent capping.",
		notMatchingInfo:
			"Room rent capping limits the amount an insurer will cover for daily hospital room rent",
		icon: SI,
		color: "#00796b",
	},
	[DAY_CARE_KEY]: {
		title: "Day Care Procedure",
		description: "Daytime medical services",
		matchingInfo:
			"Congratulations, you are among 18% of users who have an ideal  day care procedure  benefit in their policy.",
		notMatchingInfo:
			"Day care procedures refer to medical treatments or surgeries that require hospitalization for less than 24 hours.",
		icon: DC,
		color: "#d38600",
	},
	[RESTORATION_KEY]: {
		title: "Restoration",
		description: "Family Shared Health Coverage",
		matchingInfo:
			"Congratulations, you are among 27% of users who have ideal restoration benefits in their policy.",
		notMatchingInfo:
			"The restoration feature in a policy reinstates your coverage after a claim has been made.",
		icon: RE,
		color: "#778a14",
	},
	[PRE_POST_HOSPITALIZATION_KEY]: {
		title: "Pre/post hospitalization",
		description: "Coverage before and after hospitalization",
		matchingInfo:
			"Congratulations, you are among 11% of users who have ideal  pre- post hospitalisation  benefits in their policy.",
		notMatchingInfo:
			"Pre and post-hospitalization coverage protects you from the costs of doctor visits, medications, and lab tests incurred before and after your hospital stay, which typically range from INR 10,000 to 1 lakh.",
		icon: PH,
		color: "#0f4c6e",
	},
	[FAMILY_FLOATER_KEY]: {
		title: "Family Floater",
		description: "Family Floater",
		matchingInfo: "Matching",
		notMatchingInfo: "Not Matching",
		icon: prePostHospitalizationIcon,
		color: "#0f4c6e",
	},
};
