import React from "react";
import FooterFollowUs from "./FooterFollowUs";
import FooterNavBar from "./FooterNavBar";
import Logo from "../../../assets/images/ip_footer_logo.svg";

const MobileFooter = () => {
  return (
    <>
      <section className="bg-primary-color pb-6 pt-4 md:hidden w-full">
        <div className="container mx-auto">
          <div className="flex flex-col items-center">
            <img
              src={Logo}
              alt="Logo"
              style={{ width: "150px", height: "40px" }}
            />
            <div className="flex flex-col items-center font-primary-font text-xs mb-2 mt-2">
              <p className="text-white mt-4">
                Product of Karnodakshayi Insurance Pvt Ltd
              </p>
              <p className="text-white mt-4">
                Email us: customersupport@insurancepadosi.in
              </p>
              <p className="text-white mt-4">Whatsapp us : 9216652807</p>
              <FooterFollowUs />
              <p className="text-white mt-4">
                DPIIT Certificate No. DIPP143332
              </p>
            </div>
          </div>
        </div>
        <div className="text-center mt-4">
          <div className="flex flex-col lg:flex-row items-center justify-center mt-4">
            <FooterNavBar />
          </div>
        </div>
      </section>
      <div className="w-full flex flex-col bg-[#063856] items-center p-2 md:hidden">
        <p className="text-white text-xs mt-2 font-bold text-center">
          © 2023 by Karnodakshayi Insurance Pvt Ltd{" "}
        </p>
      </div>
    </>
  );
};

export default MobileFooter;
