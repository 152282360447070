import React from 'react';
import LinkedInIcon from '../../../assets/images/in.png';
import InstagramIcon from '../../../assets/images/ig.png';
import XIcon from '../../../assets/images/x.png';

const FooterFollowUs = () => {
    return (
        <div className="flex items-center mt-2">
            <p className="text-white mr-2">Follow Us:</p>
            <a href="https://www.linkedin.com/company/insurance-padosi/" className="mr-2">
                <img src={LinkedInIcon} alt="LinkedIn" className="h-4 w-4" />
            </a>
            <a href="https://www.instagram.com/insurance_padosi?igsh=MWtxMG85MDI3b2FsOA==" className="mr-2">
                <img src={InstagramIcon} alt="Instagram" className="h-4 w-4" />
            </a>
            <a href="https://x.com/insurancepadosi?s=21&t=pjtccjp_5SxSDGFwy3rbJw" className="mr-2">
                <img src={XIcon} alt="X" className="h-4 w-4" />
            </a>
        </div>
    );
};

export default FooterFollowUs;
