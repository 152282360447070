import React from 'react';

const FooterNavBar = () => {
    return (
        <div className="flex flex-wrap flex-row md:flex-col lg:flex-nowrap text-white items-end space-x-2 md:space-x-14 font-primary-font underline underline-offset-4 text-xs md:text-lg"> 
            <a href="https://insurancepadosi.in/about-us.html" className="block md:mb-3">About Us</a>
            <a href="https://www.insurancepadosi.in/terms-of-use.html" className="block md:mb-3">Terms of Use</a>
            <a href="https://www.insurancepadosi.in/privacy-policy.html" className="block md:mb-3">Privacy Policy</a>
            <a href="https://www.insurancepadosi.in/refund-policy.html" className="block md:mb-3">Refund Policy</a>
        </div>
    );
};

export default FooterNavBar;
