import Typewriter from 'typewriter-effect';
import profileLoader from '../../../assets/images/Profile-loader.json';
import Lottie from 'lottie-react';


function ProfileLoader({ userData, removeLoader }) {


    let name = userData.fname + ' ' + userData.lname;

    return (
        <div className={'flex justify-center fixed min-h-full bg-slate-900 bg-opacity-70 top-0 bottom-0 right-0 left-0 z-10'}>
            <div className="flex flex-col mt-10 h-screen items-center w-[78%]">
                <Lottie resizeMode='center' style={{ height: '300px', transform: [{ scale: 1 }], }}
                    animationData={profileLoader} loop={true} />
                <div className="max-w-[85%] border border-gray-300 rounded-md -mt-10 p-6 relative overflow-hidden">
                    <section className="flex items-center gap-5 justify-between px-5 py-5 text-white rounded-3xl border border-solid border-teal-800 border-opacity-40">
                        {/* <div className="p-3 border border-[#207058] rounded-full">
                    <img className="" src={ProfileSVG} alt="ISO" />
                </div> */}
                        <div className="flex flex-col text-lg max-w-[705px]">
                            <Typewriter
                                onInit={(typewriter) => {
                                    typewriter
                                        .typeString(`<span class="text-2xl">Hello, <strong>${name}!</strong></span>`)
                                        .pauseFor(500)
                                        .deleteAll(10);

                                    typewriter
                                        .typeString(`We are customising your <strong style="color: #ffffff;">Insurance Health Report (IHR)</strong> based on...`)
                                        .pauseFor(600)
                                        .deleteAll(10)

                                    if (userData.age && userData.city && userData.isburea) {
                                        typewriter
                                            .typeString(`Your age (<strong>${userData.age}</strong> years), location (<strong>${userData.city}</strong>) & <strong>2400+ credit variables</strong>`)
                                            .pauseFor(1000);
                                    } else {
                                        typewriter
                                            .typeString(`Your age, location, income & family details`)
                                            .pauseFor(500)
                                            .deleteAll(10);
                                    }

                                    typewriter
                                        .deleteAll(10)
                                        .typeString(`<strong>3..2..1... </strong>`)
                                        .pauseFor(500)
                                        .typeString('<strong>Ready?</strong>')
                                        .pauseFor(500)
                                        .callFunction(() => {
                                            removeLoader();  // Transition to the report display
                                        })
                                        .start();
                                }}
                                options={{
                                    autoStart: true,
                                    loop: false,
                                    delay: 60,
                                }}
                            />

                        </div>
                    </section>
                </div >
            </div>
        </div>
    );
}

export default ProfileLoader;;
