import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { auth } from '../../firebase';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const handleSignOut = () => {
    auth.signOut()
        .then(() => {
            // Sign-out successful.
            localStorage.clear();
            // Redirect to homepage
            window.location.href = '/';
        })
        .catch((error) => {
            // An error happened.
            console.error('Error logging out:', error);
        });
};

// eslint-disable-next-line react/prop-types
const Dropdown = ({ label }) => {
    return (
        <Menu as="div" className="relative inline-block text-left 'basis-1/4">
            <div>
                <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md py-3 lg:py-4 text-lg text-secondary-color">
                    {label}
                    <ChevronDownIcon className="-mr-1 h-8 w-5 text-secondary-color" aria-hidden="true" />
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-36 lg:w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                        <Menu.Item>
                            {({ active }) => (
                                <button
                                    type="submit"
                                    className={classNames(
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        'block w-full py-4 px-4 text-right lg:text-left text-sm'
                                    )}
                                    onClick={handleSignOut}
                                >
                                    Sign out
                                </button>
                            )}
                        </Menu.Item>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    );
};

export default Dropdown;