import React from "react";

import matchingIcon from "../../../../../assets/images/port-icons/Matching.svg";
import infoIcon from "../../../../../assets/images/infoicon.svg";
import Tick from "../../../../../assets/images/tick.png";
import notMatchingIcon from "../../../../../assets/images/port-icons/Not-matching.svg";
import { formatCash } from "../../../../../utils/common";
import {
  HEALTH_INSURANCE_COMPARE_KEYS,
  PRE_POST_HOSPITALIZATION_KEY,
  SUM_INSURED_KEY,
} from "../../../../../utils/constants/porting";

function CompareCard({ type, isMatching, actualValue, suggestedValue }) {
  let cardProps = HEALTH_INSURANCE_COMPARE_KEYS[type];
  const round_to_lowest_50000 = (number) => {
    if (number > 500000) {
      return number - (number % 500000);
    } else {
      return number;
    }
  };

  return (
    // <div
    // 	className={`pl-1 rounded-2xl shadow-xl font-primary-font ${isMatching || isMatching == null
    // 		? "bg-[#44D7B7]"
    // 		: "bg-[#FF5C5C]"
    // 		}`}
    // >
    <div
      style={{ backgroundColor: cardProps.color }}
      className="pl-1 rounded-2xl shadow-xl font-primary-font"
    >
      <div className="flex flex-col pb-2 w-full bg-white rounded-2xl mt-0 shadow-[0_3px_10px_rgb(0,0,0,0.2)] max-md:mt-0 max-md:max-w-full">
        <div
          style={{ backgroundColor: cardProps.color }}
          className="pl-1 rounded-t-2xl shadow-xl text-white"
        >
          <div className="flex  gap-5 max-md:gap-0 p-4">
            <div className="md:flex flex-col ">
              <img
                loading="lazy"
                src={cardProps.icon}
                alt="Co-pay icon"
                className="shrink-0 w-8 h-8 mt-2 mr-3 md:mr-0 mb-4"
              />
            </div>
            <div className="flex flex-col justify-center w-9/12 max-md:ml-0 max-md:w-full">
              <div className="flex flex-col grow justify-center ">
                {isMatching != null && (
                  <div
                    className={`w-fit px-6 py-1 rounded-lg ${
                      isMatching ? "bg-[#3c9040]" : "bg-[#ce3330]"
                    }`}
                  >
                    <h3 className={`text-sm text-left font-bold text-white`}>
                      {isMatching ? "Optimum Cover" : "Needs Attention"}
                    </h3>
                  </div>
                )}
                <h2 className="mt-1.5 font-semibold text-xl text-white whitespace-nowrap">
                  {cardProps.title}
                  {/* <br /> */}
                </h2>
                <span className="text-xs whitespace-nowrap">{cardProps.description}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-start">
        <div className="flex flex-col max-md:max-w-full">
          <div className="flex px-6 gap-10">
            {isMatching != null && (
              <>
                <div className="flex flex-col py-2">
                  <div
                    className={`flex flex-col justify-center ${
                      isMatching ? "text-teal-400" : "text-red-400"
                    } `}
                  >
                    <div
                      className={`justify-center text-xs px-1 py-2 mt-2 mb-2 text-center font-semibold rounded-xl ${
                        isMatching ? "bg-emerald-50" : "bg-rose-50"
                      }`}
                    >
                      Current
                    </div>
                  </div>
                  <div className="flex items-center gap-2.5 mt-2">
                    <img
                      loading="lazy"
                      src={isMatching ? matchingIcon : notMatchingIcon}
                      alt="Payment by you icon"
                      className="shrink-0 self-start aspect-square w-[18px] h-full"
                    />
                    <div className="flex flex-col">
                      <p
                        className={`font-semibold text-black ${
                          type === SUM_INSURED_KEY ? "text-2xl" : "text-xs"
                        }`}
                      >
                        {typeof actualValue === "boolean"
                          ? actualValue
                            ? "Included"
                            : type === PRE_POST_HOSPITALIZATION_KEY
                              ? "Not ideal"
                              : "Not included"
                          : formatCash(actualValue)}
                      </p>
                    </div>
                  </div>
                </div>
                {isMatching === false && (
                  <>
                    <div className=" shrink-0 border border-solid bg-zinc-500 bg-opacity-20 border-zinc-500 border-opacity-20 h-20 mt-2 mb-2"></div>
                    {/* <div className="md:hidden flex w-full shrink-0 h-0.5 border border-solid bg-zinc-500 bg-opacity-20 border-zinc-500 border-opacity-20 "></div> */}
                  </>
                )}
              </>
            )}
            
            {isMatching !== true && (
              <div className="flex flex-col items-start py-1">
                <div className="flex flex-col justify-center text-[#43a047]">
                  <div className="justify-center text-xs px-1 py-2 mt-3 mb-2 bg-[#e3f1e3] text-center rounded-xl">
                  {type === SUM_INSURED_KEY ? 'Optimum Cover' : 'Recommended'}
                  </div>
                </div>
                <div className="flex flex-col mb-2 ">
                  <div className="flex gap-2.5 mt-2">
                    {type !== SUM_INSURED_KEY && (
                      <img
                        loading="lazy"
                        src={Tick}
                        alt="No co-pay required icon"
                        className="shrink-0 self-start mt-1 w-3 h-3 md:w-3 md:h-3"
                      />
                    )}

                    <div className="flex flex-col">
                      <p className="text-sm font-semibold text-black">
                        {typeof suggestedValue === "boolean"
                          ? suggestedValue
                            ? type === PRE_POST_HOSPITALIZATION_KEY
                              ? "60-120 Days"
                              : "Required"
                            : "Not Required"
                          : formatCash(suggestedValue)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="m-2 flex flex-row items-start max-md:max-w-full">
          <div className="mt-2">
            {/* <svg
							className="h-6 w-6 mr-2 text-yellow-500"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
							/>
						</svg> */}
            {type === SUM_INSURED_KEY && (
              <img
                src={infoIcon}
                alt="Retirement Icon"
                className="w-6 h-6 md:w-6 md:h-6 mr-5 mb-3 md:mb-9 ml-3"
              />
            )}
          </div>
          <div className="flex items-center md:h-12 px-4 mb-5">
            <p className="text-xs pt-1 text-tertiary-color font-semibold">
              {HEALTH_INSURANCE_COMPARE_KEYS[type][
                isMatching ? "matchingInfo" : "notMatchingInfo"
              ] +
                (type === SUM_INSURED_KEY && isMatching
                  ? formatCash(round_to_lowest_50000(actualValue))
                  : "")}
            </p>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
}

export default CompareCard;
