import React from "react";
import logoExperian from "../../../../assets/images/logo-experian.png";
import WhatsAppButton from "../../../Common/WhatsAppButton";

// eslint-disable-next-line react/prop-types
const DashboardFooter = ({ creditScore }) => {
	return (
		<>
			<div className="ml-2 text-primary-color font-primary-font">
				<p className="text-md font-bold md:text-xl">
					Your Credit Score is <strong>{creditScore}</strong>
				</p>
				<p className="text-xs md:text-sm mt-4 lg:mt-1">
					This score doesn&apos;t have any co-relation with your
					Insurance Health Report
				</p>

				<p className="text-xs md:text-sm mt-2 lg:mt-1">
					Powered by&nbsp;
					<a
						href={"https://www.experian.com/"}
						target={"_blank"}
						rel="noreferrer"
					>
						<img
							src={logoExperian}
							alt={"Experian"}
							className={"inline w-20"}
						/>
					</a>
				</p>

				<div className="flex flex-col lg:flex-row items-start lg:items-center justify-between lg:mt-1">
					<p className="text-xs mt-2 lg:mt-1">
						Report any issue with the results&nbsp;
						<a
							href={
								"https://consumer.experian.in/ECSINDIA-DCE/?isDCEFlow=true"
							}
							target={"_blank"}
							rel="noreferrer"
							className={"text-secondary-color underline"}
						>
							here
						</a>
					</p>
					{/* <div className="mt-2 lg:mt-0 flex justify-center lg:justify-end w-full lg:w-auto">
						<WhatsAppButton />
					</div> */}
		  		</div>
			</div>
		</>
	);
};

export default DashboardFooter;
