import React from "react";
import Check from "../../../../assets/images/port-icons/check-circle.svg";
import Icon from "../../../../assets/images/retirementicon.png";
import Tick from "../../../../assets/images/tick.png";
import { formatCash } from "../../../../utils/common";

const RetirementCard = ({
  retirementAmount,
  deferredAnnuity,
  annualInvestment,
  retirementYears,
  canShow,
}) => {
  const insuranceFeatures = (text) => (
    <div className="flex w-full items-center">
      <img loading="lazy" src={Tick} alt="Feature icon" className="" />
      <p
        className={`ml-2 text-xs md:text-sm text-secondary-color ${!canShow ? "blur-sm" : ""}`}
      >
        {text}
      </p>
    </div>
  );

  const tenureYear = (years) => {
    const today = new Date();
    return today.getFullYear() + years;
  };
  return (
    <div className="pl-1.5 rounded-3xl shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-11/12 md:w-10/12 mt-6 bg-secondary-color font-primary-font">
      <div className="bg-white rounded-2xl">
        <div className="flex flex-col w-full max-md:max-w-full">
          <div className="bg-secondary-color py-8 px-4 md:px-12 text-white rounded-t-2xl">
            <header className="self-start ml-2 text-xl max-md:max-w-full flex items-center">
              <img
                src={Icon}
                alt="Retirement Icon"
                className="w-6 h-6 md:w-8 md:h-8 mr-2 mb-2 md:mb-8"
              />
              <div>
                <div className="text-md md:text-3xl font-semibold">
                  Retirement Planning
                </div>
                <div className="text-sm md:text-lg">
                  Securing Your Family's Future.
                </div>
              </div>
            </header>
          </div>

          <div className="px-2 md:px-4">
            <div>
              <div className="flex gap-2 md:gap-5 self-center px-7 py-4 mt-2 w-full text-left md:text-center max-w-[930px] max-md:flex-wrap max-md:px-5 max-md:max-w-full">
                {retirementAmount > 0 && (
                  <div className="flex-auto">
                    <div className="text-primary-color font-semibold text-sm md:text-md">
                      Required Corpus
                    </div>
                    <div
                      className={`text-tertiary-color text-sm md:text-xl font-bold ${
                        !canShow ? "blur-lg" : ""
                      }`}
                    >
                      {formatCash(retirementAmount)}
                    </div>
                  </div>
                )}
                {deferredAnnuity > 0 && (
                  <div className="flex-auto">
                    <div className="text-primary-color font-semibold text-sm md:text-md">
                      Deferred Annuity
                    </div>
                    <div
                      className={`text-tertiary-color text-sm md:text-xl  font-bold ${
                        !canShow ? "blur-lg" : ""
                      }`}
                    >
                      {formatCash(deferredAnnuity)}
                    </div>
                  </div>
                )}
                <div className="flex-auto">
                  <div className="text-primary-color font-semibold text-sm md:text-md">
                    Retirement Year
                  </div>
                  <div
                    className={`text-tertiary-color text-sm md:text-xl font-bold ${
                      !canShow ? "blur-lg" : ""
                    }`}
                  >
                    {tenureYear(retirementYears)}
                  </div>
                </div>
              </div>

              <hr className="shrink-0 border-solid mx-auto bg-[#848f94] border-[1px] h-[2px] w-[90%] max-md:max-w-full" />
            </div>
            {annualInvestment > 0 && (
              <div className="flex flex-col pl-5 md:mt-2 w-full max-md:max-w-full">
                <h2 className="self-start mt-4 md:mt-5 md:ml-2 text-sm md:text-md font-semibold text-secondary-color max-md:max-w-full">
                  Recommendation:
                </h2>
                <div className="self-center w-full max-md:max-w-full">
                  <div className="flex flex-col items-center mx-2 my-2 max-md:ml-0 max-md:w-full">
                    <div className="w-full">
                      <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                        <div className="flex flex-col col-span-1">
                        <p className="text-sm md:text-md font-bold text-tertiary-color">
                            Annual Investment
                          </p>
                          <div className="col-span-1 text-[#0f4c6e] text-sm md:text-xl font-semibold mt-1">
                            {formatCash(annualInvestment)}
                          </div>
                        </div>
                        <div className="flex flex-col col-span-1">
                          <p className="font-bold text-sm md:text-md text-tertiary-color">
                            Plan
                          </p>
                          <div className="col-span-1 text-[#0f4c6e] text-sm md:text-xl font-semibold mt-1">
                            Traditional/ULIP
                          </div>
                        </div>
                        <div className="flex flex-col col-span-1">
                          <p className="font-bold text-sm md:text-md text-tertiary-color">
                            Recalibration
                          </p>
                          <div className="col-span-1 text-[#0f4c6e] text-sm md:text-xl font-semibold mt-1">
                            Every 4 years
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="flex flex-col pl-2 md:pl-5 w-full max-md:max-w-full mt-3 mb-4">
              <h2 className="self-start ml-2 text-sm md:text-md font-semibold leading-10 text-tertiary-color max-md:max-w-full">
                Why do we recommend it:
              </h2>
              <div className="self-center w-full  max-md:max-w-full">
                <div className="flex flex-col items-center w-10/12 mx-2 my-2 max-md:ml-0 max-md:w-full">
                  <div className="w-full">
                    <div className="grid grid-cols-1 md:grid-cols-1 gap-2">
                      {retirementAmount > 0 &&
                        insuranceFeatures(
                          "You should invest today for your golden years tomorrow, when you will enjoy your financial freedom, travel around world & keep your peace of mind."
                        )}
                      {deferredAnnuity > 0 &&
                        insuranceFeatures(
                          "Choose first payment time as your retirement year & enjoy regular Income post-retirement."
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RetirementCard;
