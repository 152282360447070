import React, { useState, useEffect } from "react";
import meterimg from "../../../../../assets/images/meter.svg";

function CompareMeter({ score }) {
	const [currentScore, setCurrentScore] = useState(0);

	const meterValues = [
		{ label: "Urgent Action", bgColor: "bg-[#EB8180]", borderColor: "border-[#EB8180]", starts: 0, value: 50, range: [-23, -1] },
		{ label: "Insufficient", bgColor: "bg-[#F4CD2A]", borderColor: "border-[#F4CD2A]", starts: 51, value: 85, range: [2, 22] },
		{ label: "Adequate", bgColor: "bg-[#44D7B7]", borderColor: "border-[#44D7B7]", starts: 86, value: 90, range: [23, 45] },
		{ label: "Optimal", bgColor: "bg-[#066C42]", borderColor: "border-[#066C42]", starts: 91, value: 100, range: [46, 100] }
	];

	const getMeterSection = (score) => {
		return meterValues.find((meter, index) =>
			index === 0 ? score <= meter.value : score <= meter.value
		);
	};

	useEffect(() => {
		const meterSection = getMeterSection(score);
		if (meterSection) {
			const targetPosition = calculatePosition(score, meterSection.starts, meterSection.value, meterSection.range[0], meterSection.range[1]);

			const interval = setInterval(() => {
				setCurrentScore((prevPosition) => {
					if (Math.round(prevPosition - targetPosition) < 1) {
						clearInterval(interval);
						return targetPosition;
					}

					const step = Math.sign(targetPosition - prevPosition) * 0.5; // Adjust step size as needed
					return prevPosition + step;
				});
			}, 50); // Adjust the interval time as needed

			return () => clearInterval(interval); // Cleanup the interval on unmount
		} else {
			console.error("Score out of range");
		}
	}, [score]);

	const calculatePosition = (value, minValue, maxValue, minPosition, maxPosition) => {
		if (value < minValue || value > maxValue) {
			throw new Error("Value out of range");
		}
		return ((value - minValue) / (maxValue - minValue)) * (maxPosition - minPosition) + minPosition;
	};

	return (
		<div className="flex flex-col md:pt-8 py-4 mt-4 rounded-xl bg-[#ECFAF1] max-md:max-w-full shadow-[0_3px_10px_rgb(0,0,0,0.2)] p-3 relative">
			<div className="mb-1 text-[#020064]">
				<div className="flex items-center">
					<img src={meterimg} alt="score indicator" className="w-10 h-10 mr-2" />
					<h2 className="text-md">Insurance Health Score is <span className="font-bold text-xl">{score}</span></h2>
				</div>
				<div className="ml-2 text-left mt-2">
					<p className="text-md font-semibold">Your Health Insurance Score is at <span className="font-bold text-lg">{getMeterSection(score)?.label} Level</span></p>
				</div>
			</div>
			<div className="flex flex-col md:px-2 relative mb-4">
				<div className="flex items-start justify-start max-md:flex-wrap">
					<div
						style={{
							left: `${currentScore}%`,
						}}
						className="absolute top-[calc(100%+8px)] transform" id="pointer"
					>
						<div className="relative">
							<div className="bg-[#1D2145] rounded-lg text-white text-xs px-5 py-2">
								{score}
							</div>
							<div className="absolute w-3 h-3 bg-[#1D2145] left-1/2 top-[-4px] transform -translate-x-1/2 rotate-45"></div>
						</div>
					</div>

					{meterValues.map((meter, index) => (
						<div className="relative w-[25%]" key={index}>
							<div
								className={`shrink-0 mt-5 text-center border-solid border-[2px] rounded-[70px] ${meter.bgColor} ${meter.borderColor}`}
								role="progressbar"
								aria-valuenow={meter.value}
								aria-valuemin="0"
								aria-valuemax="100"
							>
								<span className="text-[11px] text-white whitespace-nowrap">{meter.label}</span>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}

export default CompareMeter;
