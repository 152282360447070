import React from "react";
import Check from "../../../../assets/images/port-icons/check-circle.svg";
import Tick from "../../../../assets/images/tick.png";
import Icon from "../../../../assets/images/termihr.png";
import { formatCash } from "../../../../utils/common";

const TermInsuranceCard = ({ value, retirementYears, canShow }) => {
  const insuranceFeatures = (text) => (
    <div className="flex w-full items-center">
      <img loading="lazy" src={Tick} alt="Feature icon" className="" />
      <p
        className={`ml-2 text-xs md:text-sm text-secondary-color ${
          !canShow ? "blur-sm" : ""
        }`}
      >
        {text}
      </p>
    </div>
  );
  return (
    <div className="pl-1.5 rounded-3xl shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-11/12 md:w-10/12 mt-6 bg-[#778a14] font-primary-font">
      <div className="bg-white rounded-2xl">
        <div className="flex flex-col w-full max-md:max-w-full">
          <div className="bg-[#778a14] py-8 px-4 md:px-12 text-white rounded-t-2xl">
			<header className="self-start ml-2 text-xl max-md:max-w-full flex items-center">
              <img
                src={Icon}
                alt="Retirement Icon"
                className="w-6 h-6 md:w-8 md:h-8 mr-2 mb-2 md:mb-4"
              />
              <div>
                <div className="text-md md:text-3xl font-semibold">
				Term Insurance
                </div>
                <div className="text-sm md:text-lg">
				Secure Your Financial Future with Comprehensive Coverage
                </div>
              </div>
            </header>
          </div>

          <div className="px-2 md:px-4 mb-3">
            <div className="flex gap-2 md:gap-5 self-center px-7 py-4 mt-2 w-full text-left md:text-center max-w-[930px] max-md:flex-wrap max-md:px-5 max-md:max-w-full">
              <div className="flex-auto">
                <div className="text-primary-color font-semibold text-sm md:text-md">
                  Cover
                </div>
                <div
                  className={`text-tertiary-color text-sm md:text-xl font-bold ${
                    !canShow ? "blur-lg" : ""
                  }`}
                >
                  {formatCash(value)}
                </div>
              </div>
              <div className="flex-auto">
                <div className="text-primary-color font-semibold text-sm md:text-md">
                  Tenure
                </div>
                <div
                  className={`text-tertiary-color text-sm md:text-xl font-bold ${
                    !canShow ? "blur-lg" : ""
                  }`}
                >
                  {retirementYears} Year
                </div>
              </div>
            </div>

            <hr className="shrink-0 border-solid mx-auto bg-[#848f94] border-[1px] h-[2px] w-[90%] max-md:max-w-full" />

            <div className="flex flex-col pl-5 md:mt-2 w-full max-md:max-w-full">
              <h2 className="self-start mt-4 md:mt-5 md:ml-2 text-md md:text-md font-semibold text-tertiary-color max-md:max-w-full">
                Factors to consider before buying term plan –
              </h2>
              <div className="self-center w-full max-md:max-w-full">
                <div className="flex flex-col items-center mx-2 my-2 max-md:ml-0 max-md:w-full">
                  <div className="w-full">
                    <div className="grid grid-cols-1 gap-3">
                      {insuranceFeatures("Claim settlement ratio over 95%")}
                      {insuranceFeatures(
                        "Sum assured should cover your liabilities"
                      )}
                      {insuranceFeatures(
                        "Account for your dependents' long-term aspirations"
                      )}
                      {insuranceFeatures("Evaluate the insurer's reputation")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermInsuranceCard;
