import React, { useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { screensConfig } from './screensConfig';
import toast, { Toaster } from 'react-hot-toast';
import { ihrquestionsConfig } from './ihrquestionsConfig';
import renderQuestion from './renderQuestion';
import CouponComponent from './CouponComponent';
import ProgressBar from '../../../Common/Loaders/ProgressBar';
import Spinner from '../../../Common/Loaders/Spinner';
import HealthInsurance from './HealthInsurance';
import { useApi } from '../../../../store/services/useApi';
import ROUTES from '../../../../utils/constants/Routes';
import { useLocation, useNavigate } from "react-router-dom";
import { useGetHealthProductsQuery } from '../../../../store/services/healthproducts';


const cashfree = window.Cashfree({
  mode: process.env.REACT_APP_ENV === "production" ? "production" : "sandbox",
});

const IHRQuestions = () => {
  const sessionData = useSelector((state) => state?.session?.sessionData);
  const methods = useForm();
  const { register, handleSubmit, setValue, watch, formState: { errors } } = methods;
  const [filteredScreens, setFilteredScreens] = useState(screensConfig);
  const [showCoupon, setShowCoupon] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null);
  const [selectedPricing, setSelectedPricing] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isbureau, setIsBureau] = useState(false);

  const [currentScreenIndex, setCurrentScreenIndex] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  // for gpay port journey
  const gpayHealth = localStorage.getItem('Lhr') === 'true';

  // const gpayHealth = true;
  // const searchParams = new URLSearchParams(location.search);
  // const gpayHealth = searchParams.get('Lhr') === 'true';
  // console.log("gpay", gpayHealth);
  // console.log("code", code)

  const questionToProfileMap = {
    annual_income: 'income',
  };

  const [couponId, setCouponId] = useState(null);
  const product_url = "ihr";

  const handleDiscountApplied = ({ couponId }) => {
    setCouponId(couponId);
    // console.log("coupon final", couponId);
  };

  const handlePricingSelected = (pricing) => {
    setSelectedPricing(pricing);
  };

  useEffect(() => {
    // Set the isbureau value from sessionData
    if (sessionData?.isburea !== undefined) {
      setIsBureau(sessionData.isburea);
    }
  }, [sessionData]);

  const [answers, setAnswers] = useState(() => {
    return screensConfig.reduce((acc, screen) => {
      screen.questions.forEach(questionId => {
        // Check in sessionData and userProfile
        const profileKey = questionToProfileMap[questionId] || questionId;
        acc[questionId] = sessionData?.[questionId] || sessionData?.userProfile?.[profileKey] || sessionData?.userPersonalDetails?.[profileKey] || '';
      });
      return acc;
    }, {});
  });

  const { makeCall: saveAnswer, isLoading: savingAnswers, data: answerResponse, error: answerError } = useApi("POST", "/save/user/answer");

  const family = watch('family', answers.family || []);
  // const numChildren = watch('no_of_children', answers.no_of_children);

  const healthInsurance = watch('health_insurance', answers.health_insurance || '');
  const employerProvided = watch('employer_provided_insurance', answers.employer_provided_insurance || '');
  const purchaseBy = watch('purchased_by', answers.purchased_by || '');

  // Update answers and filtered screens when health insurance-related fields change
  useEffect(() => {
    setValue('employer_provided_insurance', null);
    setValue('purchased_by', null);
    setAnswers(prevAnswers => ({
      ...prevAnswers,
      health_insurance: healthInsurance,
      employer_provided_insurance: null,
      purchased_by: null,
    }));
    updateFilteredScreens();
  }, [healthInsurance]);

  useEffect(() => {
    setAnswers(prevAnswers => ({
      ...prevAnswers,
      employer_provided_insurance: employerProvided,
    }));
    updateFilteredScreens();
  }, [employerProvided]);

  useEffect(() => {
    setAnswers(prevAnswers => ({
      ...prevAnswers,
      purchased_by: purchaseBy,
    }));
    updateFilteredScreens();
  }, [purchaseBy]);

  useEffect(() => {
    updateFilteredScreens();
    // console.log("filter before", filteredScreens);
  }, [answers]);

  const { data, isLoading, isError } = useGetHealthProductsQuery();
  function findProductById(data, id) {
    // Loop through each insurer in the data
    for (const insurerName in data) {
      const products = data[insurerName];

      // Loop through each product offered by the insurer
      for (const product of products) {
        if (product.id === parseInt(id)) {
          return product;

        }
      }
    }
    console.log("not found");
    return null;

  }

  useEffect(() => {
    // Get the productId from answers
    const productId = answers.health_insurance_product_id;

    if (!productId || !data) return;
    const product = findProductById(data, productId);

    if (product) {
      setValue('health_insurance_insurer_name', product.insurer_name);
      setValue('health_insurance_product_id', product.id);

      setAnswers(prevAnswers => ({
        ...prevAnswers,
        health_insurance_insurer_name: product.insurer_name,
        health_insurance_product_id: product.id,
      }));
    }
  }, [answers.health_insurance_product_id, data, setValue, setAnswers]);


  // Check if "Children" is selected in the "Immediate Family" screen
  const shouldShowNumChildren = family.includes('children');

  // Function to update filtered screens based on current answers
  const updateFilteredScreens = () => {
    const updatedScreens = screensConfig.filter(screen => {
      // Remove the email question for bureau users
      if (isbureau && screen.id === 'emailDetails') {
              return false;
        }

      if (screen.conditional) {
        let conditionValues;

        if (Array.isArray(screen.conditional.dependsOn)) {
          conditionValues = {};
          screen.conditional.dependsOn.forEach(dep => {
            conditionValues[dep] = answers[dep];
          });
        } else {
          conditionValues = answers[screen.conditional.dependsOn];
        }

        if (!screen.conditional.condition(conditionValues)) {
          return false;
        }
      }

      // Identify if the screen has health insurance-related questions
      const hasHealthInsuranceQuestions = screen.questions.some(q =>
        ["health_insurance_insurer_id", "health_insurance_product_id", "health_insurance_sum_insured"].includes(q)
      );

      // If it has health insurance questions, skip the sessionData check but still include the screen
      if (hasHealthInsuranceQuestions) {
        return true;
      }

      const unansweredQuestions = screen.questions.filter(q =>
        !sessionData[q] && !sessionData?.userProfile?.[questionToProfileMap[q] || q] && !sessionData?.userPersonalDetails?.[q]
      );
      if (unansweredQuestions.length === 0) {
        return false;
      }

      // for gpay port journey
      if (gpayHealth) {
        // If gpayHealth is true, include all screens
        return true;
      } else {
        // If gpayHealth is false, exclude screens related to health insurance
        return !['healthInsuranceDetails', 'insuranceDetails', 'planValueDetails'].includes(screen.id);
      }

      return true;
    });

    setFilteredScreens(updatedScreens);
  };

  const currentScreen = filteredScreens[currentScreenIndex];
  const isLastScreen = currentScreenIndex === filteredScreens.length - 1;

  // Calculate the percentage for the progress bar
  const totalScreens = screensConfig.length;
  const currentIndexInTotal = screensConfig.findIndex(screen => screen.id === currentScreen.id);
  const percentage = Math.round(((currentIndexInTotal + 1) / totalScreens) * 100);

  useEffect(() => {
    currentScreen.questions.forEach(questionId => {
      setValue(questionId, answers[questionId]);
    });
  }, [currentScreenIndex, setValue, answers, currentScreen]);

  // Render conditional sub-questions
  const renderConditionalSubQuestions = () => {
    let subQuestions = [];

    const numChildren = watch('no_of_children', answers.no_of_children);

    if (currentScreen.id === 'numChildrenAndAges' && shouldShowNumChildren) {
      if (numChildren === '1') {
        subQuestions.push('eldest_child_age');
      } else if (numChildren === '2') {
        subQuestions.push('youngest_child_age', 'eldest_child_age');
      } else if (numChildren === '3+') {
        subQuestions.push('youngest_child_age', 'eldest_child_age');
      }
    }

    if (currentScreen.id === 'healthInsuranceDetails') {
      if (healthInsurance === 'yes') {
        subQuestions.push('purchased_by');
      } else if (healthInsurance === 'no') {
        subQuestions.push('employer_provided_insurance');
      }
    }


    return subQuestions.map(questionId => (
      <div key={questionId} className='mt-6 lg:mt-3 '>

        <label className="font-bold text-xs lg:text-lg text-blue-950 mt-16 mb-12 lg:mb-2">
          {ihrquestionsConfig.find(q => q.id === questionId).label}
        </label>

        <div className='mt-4 mb-4'>
          {renderQuestion(
            ihrquestionsConfig.find(q => q.id === questionId),
            register,
            errors,
            setValue,
            watch
          )}
        </div>
      </div>
    ));
  };

  // useEffect(() => {
  //   console.log("ans", answers);
  // }, [answers]);

  // useEffect(() => {
  //   console.log("filtered", filteredScreens);
  //   console.log("length", filteredScreens.length);
  // }, [filteredScreens]);

  // useEffect(() => {
  //   console.log("current", currentScreen);
  //   console.log("index", currentScreenIndex);
  // }, [currentScreen, currentScreenIndex]);

  // useEffect(() => {
  //   console.log("last", isLastScreen);
  // }, [isLastScreen]);

  useEffect(() => {
    if (answerResponse?.orderDetails?.pg_session_id) {
      // Cashfree checkout if pg_session_id exists
      cashfree.checkout({
        paymentSessionId: answerResponse.orderDetails.pg_session_id,
        redirectTarget: "_self",
      });
    } else if (answerResponse?.orderCreated) {
      console.log("navigate to dashboard for free order");
      navigate(ROUTES.IHR_DASHBOARD, {
        replace: true,
        state: { refreshSession: true },
      });
    }
    else {
      console.log("error in order creation");
    }
  }, [answerResponse])
  console.log(answerError);
  useEffect(() => {
    if (answerError) {
      toast.error("Something went wrong.", {
        duration: 5000,
        position: "top-center",
      });
    }
  }, [answerError]);

  const handleNext = async (data) => {
    // console.log('handleNext triggered', data);
    const currentAnswers = {};

    // answers from current screen
    currentScreen.questions.forEach(questionId => {
      if (data[questionId] !== undefined) {
        currentAnswers[questionId] = data[questionId];
      } else if (sessionData[questionId] !== undefined) {
        currentAnswers[questionId] = sessionData[questionId];
      }
    });

    // answers from sub-questions if any
    const subQuestions = renderConditionalSubQuestions().map(child => child.key);
    subQuestions.forEach(questionId => {
      if (data[questionId] !== undefined) {
        currentAnswers[questionId] = data[questionId];
      } else if (sessionData[questionId] !== undefined) {
        currentAnswers[questionId] = sessionData[questionId];
      }
    });

    // Merge with existing answers
    const updatedAnswers = { ...answers, ...currentAnswers };
    setAnswers(updatedAnswers);

    // Define the next screen
    const nextScreen = filteredScreens[currentScreenIndex + 1];
    const isNextScreenHealthInsurance = nextScreen && nextScreen.id === 'healthInsuranceDetails';

    // Determine if generateIHR should be true
    const generateIHR = isLastScreen || isNextScreenHealthInsurance;

    // Create payload
    const payload = {
      answers: Object.keys(currentAnswers).map(questionId => ({
        id: questionId,
        answer: currentAnswers[questionId],
      })),
      generateIHR, // Set generateIHR true if it’s the last question or last question before health insurance
      order: {
        create: isLastScreen,
        pricing_id: 1,
        discount_coupon_id: couponId || null,
      }
    };

    // saveAnswers(payload);
    let response = null;
    if (gpayHealth && isLastScreen) {
      setLoading(true); // Start loading
      try {
        saveAnswer(payload);
      } finally {
        setLoading(false); // Stop loading
      }
    } else {
      saveAnswer(payload);
    }

    if (currentScreenIndex < filteredScreens.length - 1) {
      //for other screens
      setCurrentScreenIndex(currentScreenIndex + 1);
    } else {
      //for last screen
      if (gpayHealth) {
        console.log(response)

      } else {
        console.log("redirect for non gpay order");
        navigate(ROUTES.IHR_DASHBOARD, {
          replace: true,
          state: { refreshSession: true },
        });

      }
    }
  };

  const handlePrevious = () => {
    if (currentScreenIndex > 0) {
      setCurrentScreenIndex(currentScreenIndex - 1);
    }
  };

  const renderQuestions = (questions) => {
    return questions.map(questionId => {
      const question = ihrquestionsConfig.find(q => q.id === questionId);

      return (
        <div key={questionId} className='font-primary-font'>
          {question.title && (
            <p className="text-lg font-bold lg:text-3xl text-tertiary-color lg:mb-8 mb-2 mt-4 md:mt-1">
              {question.title}
            </p>
          )}
          <label className="font-semibold text-xs lg:text-lg text-tertiary-color mt-12 mb-12 lg:mb-2">
            {question.label}
          </label>
          <div className='mt-4 mb-4'>
            {renderQuestion(
              question,
              register,
              errors,
              setValue,
              watch
            )}
          </div>
          {question.message && (
            <p className="text-[10px] lg:text-sm lg:rounded-xl text-secondary-color font-semibold text-center p-3 bg-[#d9ebe9] text-md mt-6">
              {question.message}
            </p>
          )}
        </div>
      );
    });
  };

  const currentQuestionConfig = ihrquestionsConfig.find(
    q => q.id === currentScreen.questions[0]
  );

  useEffect(() => {
    // Show coupon only if it's the last screen for non gpay journey
    if (!gpayHealth && isLastScreen) {
      // Show coupon only if gpayHealth is false and it's the last screen
      setShowCoupon(true);
    } else {
      // for gpay journey
      if (currentScreen.id === 'insuranceDetails' || currentScreen.id === 'planValueDetails') {
        setShowCoupon(true);
      } else if (currentScreen.id === 'healthInsuranceDetails') {
        // Only show if health insurance is 'no' and purchased_by is 'no'
        const healthValuesCheck = healthInsurance === 'no' && employerProvided === 'no';
        setShowCoupon(healthValuesCheck);
      } else {
        setShowCoupon(false);
      }
    }
  }, [gpayHealth, isLastScreen, currentScreen.id, healthInsurance, employerProvided]);

  const isButtonDisabled = () => {
    //disable if any sub questions in health insurance are not answered
    if (healthInsurance === 'yes' && !purchaseBy) {
      return true;
    }
    if (healthInsurance === 'no' && !employerProvided) {
      return true;
    }
    // Disable if there are any other validation errors or unanswered required questions
    return Object.keys(errors).length || !currentScreen.questions.every(q => watch(q));
  };

  return (
    <FormProvider {...methods}>
      <Toaster />
      <form onSubmit={handleSubmit(handleNext)} className="flex flex-col min-h-screen pt-2 lg:w-[1100px] bg-[#f3f5f6] font-primary-font">

        <div className="lg:relative lg:flex lg:flex-row gap-8 p-2">
          {/* Left Column: Progress bar, text, and image */}
          <div className="text-[#667085] lg:w-1/2 flex flex-row lg:flex lg:flex-col bg-[#e7ebed] lg:p-6 justify-center items-center shadow-lg lg:m-8 rounded-xl">
            <div className="lg:w-auto lg:h-auto">
              <ProgressBar percentage={percentage} />
            </div>
            <div className="px-2 mt-4">
              <p className="text-xs lg:text-sm text-center font-semibold font-sans">
                {currentQuestionConfig.description}
              </p>
            </div>

            <img
              src={currentQuestionConfig.image}
              alt="Question Illustration"
              className="mt-2 hidden lg:block"
              style={{ width: "250px", height: "250px" }}
            />
          </div>

          {/* Right Column: Question title, subtitle, inputs, and messages */}
          <div className="lg:w-1/2 lg:flex mt-4 lg:mt-7 lg:mr-8 lg:flex-col p-2">
            {currentScreen.id === 'insuranceDetails' ? (
              <HealthInsurance setValue={setValue} errors={errors} />
            ) : (
              <>
                {renderQuestions(currentScreen.questions)}
                {renderConditionalSubQuestions()}
              </>
            )}

            {/* {showCoupon && (
              <CouponComponent
                productUrl={product_url}
                gpayHealth={gpayHealth}
                onDiscountApplied={handleDiscountApplied}
                onPricingSelected={handlePricingSelected}
              />
            )} */}

            <div className="lg:flex justify-between flex flex-row-reverse mt-10 mb-6 lg:right-0 lg:bottom-0 w-full">

              {/* Continue Button */}

              <button
                type="submit"
                className={`text-xs md:text-lg rounded whitespace-nowrap px-10 md:px-14 py-2 ml-8 lg:ml-0 lg:w-auto font-bold rounded-xl shadow-xl ${isButtonDisabled() ? 'bg-gray-200 text-gray-500 cursor-not-allowed' : 'primary-btn cursor-pointer'
                  }`}
                disabled={isButtonDisabled()}
              >

                {savingAnswers ? (
                  <Spinner />
                ) : isLastScreen ? (
                  gpayHealth ? (
                    couponId ? 'Generate IHR for ₹0' : 'Generate IHR for ₹99'
                  ) : 'Generate IHR'
                ) : (
                  "Continue"
                )}
              </button>


              {/* Previous Button */}
              {currentScreenIndex > 0 && (
                <button
                type="button"
                onClick={handlePrevious}
                className="text-xs md:text-lg py-3 w-full lg:w-auto text-primary-color font-semibold cursor-pointer ml-4 flex items-center"
              >
                <span className="w-0 h-0 border-y-8 border-y-transparent border-r-8 border-primary-color mr-2 md:mr-3"></span>
                Previous Question
              </button>
              
              )}
            </div>

          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default IHRQuestions;
