import React from 'react';

const renderQuestion = (question, register, errors, setValue, watch) => {
  const commonInputClasses = "border p-4 w-full rounded-2xl";
  const commonErrorClasses = "text-red-500 text-xs mt-1";

  switch (question.type) {
    case 'text':
      return (
        <div>
          <input
            type="text"
            {...register(question.id, question.validation)}
            defaultValue={question.defaultValue || ''}
            className={`${commonInputClasses} placeholder-tertiary-color`}
            placeholder={question.placeholder || ''}
          />
          {errors[question.id] && <p className={commonErrorClasses}>{errors[question.id]?.message}</p>}
        </div>
      );

    case 'numeric':
      return (
        <div>
          <input
            type="number"
            {...register(question.id, question.validation)}
            defaultValue={question.defaultValue || ''}
            className={`${commonInputClasses} placeholder-tertiary-color`}
            placeholder={question.placeholder || ''}
          />
          {errors[question.id] && <p className={commonErrorClasses}>{errors[question.id]?.message}</p>}
        </div>
      );

    case 'select':
      return (
        <div>
          <select
            {...register(question.id, question.validation)}
            defaultValue={question.defaultValue || ''}
            className={commonInputClasses}
          >
            {question.options.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          {errors[question.id] && <p className={commonErrorClasses}>{errors[question.id]?.message}</p>}
        </div>
      );

        case 'checkbox':
          const selectedOptions = watch(question.id) || [];
          const handleCheckboxClick = (value) => {
            let newValues = selectedOptions.includes(value)
              ? selectedOptions.filter(v => v !== value)
              : [...selectedOptions, value];
            
            // Deselect all if "None" is selected
            if (value === 'none') {
              newValues = ['none'];
            } else {
              newValues = newValues.filter(v => v !== 'none');
            }

            // Ensure at least "None" is selected if no other options are selected
            if (newValues.length === 0) {
              newValues = ['none'];
            }
            
            setValue(question.id, newValues, { shouldValidate: true });
          };

        return (
          <div>
            {question.options.map(option => (
              <label key={option.value} className="inline-block lg:mt-4 mt-4 lg:w-auto w-full">
                <input
                  type="checkbox"
                  value={option.value}
                  checked={selectedOptions.includes(option.value)}
                  onChange={() => handleCheckboxClick(option.value)}
                  className="hidden"  // Hide the default checkbox
                />
                <span
                  className={`flex items-center justify-center border border-primary-color rounded-xl text-sm font-semibold text-tertiary-color lg:px-12 px-28 py-3 lg:mr-4 lg:ml-4 w-full lg:w-[150px] ${
                    selectedOptions.includes(option.value)
                      ? 'bg-[#b0d5d1]'
                      : ''
                  }`}
                >
                  {option.label}
                </span>
              </label>
            ))}
            {errors[question.id] && (
              <p className="text-red-500 text-xs mt-1">
                {errors[question.id]?.message}
              </p>
            )}
          </div>
        );

  
      case 'radio':
        const selectedRadio = watch(question.id);
  
        return (
          <div>
            {question.options.map(option => (
              <label 
              key={option.value} 
              className={`${
                question.id === 'purchased_by' 
                  ? 'block mb-2 lg:inline-block' 
                  : 'inline-block'
              } ${question.id !== 'no_of_children' ? 'lg:w-auto w-full mt-2' : 'mt-4'}`}
              >
                <input
                  type="radio"
                  value={option.value}
                  checked={selectedRadio === option.value}
                  onChange={() => setValue(question.id, option.value, { shouldValidate: true })}
                  className="hidden"  // Hide the default radio button
                />
                <span
                  className={`border p-4 border-primary-color rounded-xl text-sm font-semibold text-tertiary-color mr-4 
                    ${selectedRadio === option.value ? 'bg-[#b0d5d1]' : ''} 
                    ${question.id !== 'no_of_children' ? 'flex items-center justify-center lg:w-auto w-full' : ''}`}
                >
                  {option.label}
                </span>
              </label>
            ))}
            {errors[question.id] && (
              <p className="text-red-500 text-xs mt-1">
                {errors[question.id]?.message}
              </p>
            )}
          </div>
        );

    case 'date':
      return (
        <div>
          <input
            type="date"
            {...register(question.id, question.validation)}
            defaultValue={question.defaultValue || ''}
            className={commonInputClasses}
          />
          {errors[question.id] && <p className={commonErrorClasses}>{errors[question.id]?.message}</p>}
        </div>
      );

    default:
      return null;
  }
};

export default renderQuestion;
