import React from "react";
import Check from "../../../../assets/images/port-icons/check-circle.svg";
import Icon from "../../../../assets/images/cardiacihr.png";
import IIcon from "../../../../assets/images/infoicon.svg";
import { formatCash } from "../../../../utils/common";

const AlimentCard = ({
  cancerCareValue,
  cardiacCareValue,
  retirementYears,
  canShow,
}) => {
  return (
    <>
      <div className="flex flex-col md:flex-row w-11/12 md:w-9/12 mt-6 space-y-6 md:space-y-0 md:space-x-4 font-primary-font">
        {/* Cancer Care Card */}
        <div className="pl-1.5 rounded-3xl shadow-[0_3px_10px_rgb(0,0,0,0.2)] md:w-6/12 bg-[#d38600] ">
          <div className="bg-white rounded-2xl">
            <div className="flex flex-col ">
              <div className="bg-[#d38600]  py-6 px-4 md:px-8 text-white rounded-t-2xl">
                {" "}
                <header className="self-start ml-2 text-xl flex items-center">
                  <img
                    src={Icon}
                    alt="Cancer Care Icon"
                    className="w-6 h-6 md:w-8 md:h-8 mr-2 mb-2"
                  />
                  <div>
                    <div className="text-lg md:text-2xl font-semibold">
                      {" "}
                      Required Cancer Care
                    </div>
                  </div>
                </header>
              </div>

              <div className="px-6 pt-4 md:px-6">
                <span className="inline-block px-4 py-2 text-[#43a047] bg-[#e3f1e3] font-semibold rounded-xl text-sm md:text-xs font-medium">
                  Optimum Cancer Cover
                </span>
              </div>

              <div className="px-6 md:px-7 pt-4">
                <div className="flex justify-between text-primary-color">
                  <p>Cover</p>
                  <p>Tenure</p>
                </div>
                <div className="flex justify-between text-tertiary-color text-md md:text-xl font-bold mb-2">
                  <p className={`${!canShow ? "blur-lg" : ""}`}>
                    {formatCash(cancerCareValue)}
                  </p>
                  <p className={`${!canShow ? "blur-lg" : ""}`}>
                    {retirementYears} Years
                  </p>
                </div>

                <hr className="shrink-0 border-solid mx-auto bg-[#848f94] border-[1px] h-[2px] w-[90%] max-md:max-w-full" />
                <div className="flex items-center py-5">
                  <img src={IIcon} alt="Info Icon" className="w-5 h-5 mr-2 mb-14 md:mb-9" />
                  <p className="text-sm text-tertiary-color">
                    Cancer cover provides financial protection to help cover the
                    high costs of cancer treatment within an insurance policy.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Cardiac Care Card */}
        <div className="pl-1.5 rounded-3xl shadow-[0_3px_10px_rgb(0,0,0,0.2)] md:w-6/12 bg-secondary-color font-primary-font">
          <div className="bg-white rounded-2xl">
            <div className="flex flex-col">
              <div className="bg-secondary-color py-6 px-4 md:px-8 text-white rounded-t-2xl">
                {" "}
                <header className="self-start ml-2 text-xl flex items-center">
                  <img
                    src={Icon}
                    alt="Cardiac Care Icon"
                    className="w-6 h-6 md:w-8 md:h-8 mr-2 mb-2"
                  />
                  <div>
                    <div className="text-lg md:text-2xl font-semibold">
                      {" "}
                      Required Cardiac Care
                    </div>
                  </div>
                </header>
              </div>

			  <div className="px-6 pt-4 md:px-6">
                <span className="inline-block px-4 py-2 text-[#43a047] bg-[#e3f1e3] font-semibold rounded-xl text-sm md:text-xs font-medium">
                  Optimum Cardiac Cover
                </span>
              </div>

              <div className="px-6 md:px-7 pt-4">
                <div className="flex justify-between text-primary-color">
                  <p>Cover</p>
                  <p>Tenure</p>
                </div>
                <div className="flex justify-between text-tertiary-color text-md md:text-xl font-bold mb-2">
                  <p className={`${!canShow ? "blur-lg" : ""}`}>
                    {formatCash(cardiacCareValue)}
                  </p>
                  <p className={`${!canShow ? "blur-lg" : ""}`}>
                    {retirementYears} Years
                  </p>
                </div>

                <hr className="shrink-0 border-solid mx-auto bg-[#848f94] border-[1px] h-[2px] w-[90%] max-md:max-w-full" />
                <div className="flex items-center py-5">
                  <img src={IIcon} alt="Info Icon" className="w-5 h-5 mr-2 mb-20 md:mb-9" />
                  <p className="text-sm text-tertiary-color">
                    Cardiac cover provides financial protection for the high
                    costs associated with heart-related treatments within an
                    insurance policy.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AlimentCard;
