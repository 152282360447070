import React from 'react';
import PropTypes from 'prop-types';

const ProgressBar = ({percentage}) => {
    const radius = 70;
    const strokeWidth = 10;
    const normalizedRadius = radius - strokeWidth * 2;
    const circumference = normalizedRadius * 2 * Math.PI;
    const strokeDashoffset = circumference - (percentage / 100) * circumference;

    return (
        <svg height={radius * 2} width={radius * 2}>
            <circle
                stroke="#bec3c9"
                fill="transparent"
                strokeWidth={strokeWidth}
                r={normalizedRadius}
                cx={radius}
                cy={radius}
            />
            <circle
                stroke="#00796b"
                fill="transparent"
                strokeWidth={strokeWidth}
                strokeDasharray={circumference + ' ' + circumference}
                style={{ strokeDashoffset }}
                r={normalizedRadius}
                cx={radius}
                cy={radius}
                transform={`rotate(-90 ${radius} ${radius})`}

            />
            <text
                x="50%"
                y="50%"
                textAnchor="middle"
                dominantBaseline="central"
                fill="#2c3e50"
                fontSize="24"
                fontWeight="bold"
            >
                {Math.round(percentage)}%
            </text>
        </svg>
    );
};

//     return (
//         <div className={'w-full my-8 lg:my-12'}>
//             <div className={'bg-gray-200'}>
//                 <div className={'bg-green-700 h-2'} style={{ width: `${percentage}%` }} />
//             </div>
//             <div className={'text-right text-black font-bold mt-1'}>{Math.round(percentage)}%</div>
//         </div>
//     );
// };

ProgressBar.propTypes = {
    percentage: PropTypes.number
};

export default ProgressBar;