import React from "react";
import Logo from "../../../assets/images/ip_footer_logo.svg";
import FooterNavBar from "./FooterNavBar";
import FooterFollowUs from "./FooterFollowUs";
import MobileFooter from "./MobileFooter";

const Footer = () => {
  return (
    <>
      <footer className="bg-primary-color py-8 lg:px-28 hidden md:block w-full">
        <div className="container mx-auto">
          <div className="flex justify-between items-center">
            <img src={Logo} alt="Logo" className="mr-2 h-12 w-18" />
          </div>
          <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center">
            <div className="w-full lg:w-auto flex justify-center lg:justify-start">
              <div className="flex items-start flex-col mb-4 ml-4 mt-4 font-primary-font text-white font-semibold">
                <p className="mt-2">
                  Product of Karnodakshayi Insurance Pvt Ltd
                </p>
                <p className="mt-2">
                  Email us: customersupport@insurancepadosi.in
                </p>
                <p className="mt-2">Whatsapp us : 9216652807</p>
                <FooterFollowUs />
                <p className="mt-2">DPIIT Certificate No. DIPP143332</p>
              </div>
            </div>

            <div className="flex flex-row justify-end items-end text-right">
              <FooterNavBar />
            </div>
          </div>
        </div>
      </footer>
      <div className="w-full flex flex-col bg-[#063856] items-center p-2 hidden md:block">
        <p className="text-white mt-2 font-bold text-center">
          © 2023 by Karnodakshayi Insurance Pvt Ltd{" "}
        </p>
      </div>
      <MobileFooter />
    </>
  );
};

export default Footer;
